import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../../../../UI/button';
import Field from '../../../../UI/field';
import GreenRedButtonBlock from '../../../../UI/green-red-button-block';
import BotTableRow from '../../../../UI/bot-table-row';
import AreUSureModal from '../../../../UI/are-u-sure-modal';

import style from './inr-bot-view.module.scss';

const InrBotView = ({
    botsData,
    inputOnchange,
    updateINRBots,
    updateINRBotsSuccess,
    updateINRBotsError,
    areUSureModal,
    createNewBot,
    stopAllBots,
    yesBtnAreUSureModal,
    switchAreUSureModal,
    switchStatusProvider,
    deleteProviderBot,
    cancelOrders,
    editProviderBot,
    cannotChahgeBot,
    startAllBots,
    updatefixedrates,
    disabledStartAllBtn,
}) => {
    const { t } = useTranslation();

    return (
        <div className={style.botsWrapper}>
            <div className={style.botsWrapper__bot}>
                <p className={style.botsWrapper__bot_title}>
                    {t('aside.liquidityProvider')} / {t('aside.inrBot')}
                </p>
                <div className={style.botsWrapper__updatecard}>
                    <div className={style.botsWrapper__wrapperInput}>
                        <Field
                            id="updateINRBots"
                            type="number"
                            placeholder={t('INRBot.updateInput')}
                            name="updateINRBots"
                            value={updateINRBots}
                            error={updateINRBotsError}
                            onChange={inputOnchange}
                            inputStyle={style.botsWrapper__wrapper_input}
                        />
                    </div>
                    <div className={style.buttonsWrapper}>
                        <Button
                            onClick={updatefixedrates}
                            className={style.startAllBotsBtn}
                            type="button"
                            disabled={!updateINRBotsSuccess}
                        >
                            {t('generals.resetAll')}
                        </Button>
                    </div>
                </div>
                <BotTableRow
                    botsData={botsData}
                    switchStatusProvider={switchStatusProvider}
                    deleteProviderBot={deleteProviderBot}
                    editProviderBot={editProviderBot}
                    cannotChahgeBot={cannotChahgeBot}
                    cancelOrders={cancelOrders}
                    isCancelOrders
                />
            </div>
            <AreUSureModal
                visibleModal={areUSureModal}
                noButton={switchAreUSureModal}
                yesButton={yesBtnAreUSureModal}
            />
        </div>
    );
};

InrBotView.defaultProps = {
    botsData: [],
    areUSureModal: false,
    disabledStartAllBtn: false,
    inputOnchange: () => { },
    updatefixedrates: () => { },
    startAllBots: () => {},
    updatefixedrates: () => {},
    stopAllBots: () => {},
    createNewBot: () => {},
    switchStatusProvider: () => {},
    deleteProviderBot: () => {},
    cancelOrders: () => {},
    editProviderBot: () => {},
    yesBtnAreUSureModal: () => {},
    switchAreUSureModal: () => {},
    cannotChahgeBot: () => {},
};

InrBotView.propTypes = {
    botsData: PropTypes.arrayOf(PropTypes.object),
    inputOnchange: PropTypes.func,
    updatefixedrates: PropTypes.func,
    areUSureModal: PropTypes.bool,
    disabledStartAllBtn: PropTypes.bool,
    startAllBots: PropTypes.func,
    updatefixedrates: PropTypes.func,
    stopAllBots: PropTypes.func,
    createNewBot: PropTypes.func,
    switchStatusProvider: PropTypes.func,
    deleteProviderBot: PropTypes.func,
    cancelOrders: PropTypes.func,
    editProviderBot: PropTypes.func,
    yesBtnAreUSureModal: PropTypes.func,
    switchAreUSureModal: PropTypes.func,
    cannotChahgeBot: PropTypes.func,
};

export default InrBotView;
