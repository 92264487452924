import { editBotConstants } from '../constants';

const editProviderBot = (state, action) => {
    if (state === undefined) {
        return {
            editProviderBot: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
    case editBotConstants.EDIT_BOT_REQUEST:
        return {
            editProviderBot: {},
            loading: true,
            error: null,
            success: false,
        };

    case editBotConstants.EDIT_BOT_SUCCESS:
        return {
            editProviderBot: action.payload,
            loading: false,
            error: null,
            success: true,
        };

    case editBotConstants.EDIT_BOT_FAILURE:
        return {
            editProviderBot: {},
            loading: false,
            error: action.payload,
            success: false,
        };

    default:
        return state;
    }
};

export default editProviderBot;
