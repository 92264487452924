import { stopAllBots } from '../constants';

const stopAllProviderBots = (state, action) => {
    if (state === undefined) {
        return {
            stopAllBots: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
    case stopAllBots.STOP_ALL_REQUEST:
        return {
            stopAllBots: {},
            loading: true,
            error: null,
            success: false,
        };

    case stopAllBots.STOP_ALL_SUCCESS:
        return {
            stopAllBots: action.payload,
            loading: false,
            error: null,
            success: true,
        };

    case stopAllBots.STOP_ALL_FAILURE:
        return {
            stopAllBots: {},
            loading: false,
            error: action.payload,
            success: false,
        };

    default:
        return state;
    }
};

export default stopAllProviderBots;
