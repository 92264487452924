/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import style from './provider-statistics-page.module.scss';
import './provider-statistics-page.scss';

const ProviderStatisticsView = ({
    match: {
        params: { id: botId },
    },
    prepearedVolumeSell,
    prepearedVolumeBuy,
    sentVolumeSell,
    sentVolumeBuy,
    accuracyPreparedVolumeBuy,
    accuracyPreparedVolumeSell,
    accuracySentVolumeBuy,
    accuracySentVolumeSell,
    configuredVolume,
    workLoadTimeLine,
    lifeCycleTimeLine,
}) => {
    const { t } = useTranslation();

    const rangeSelector = {
        rangeSelector: {
            buttons: [
                {
                    type: 'minute',
                    count: 1,
                    text: '1min',
                },
                {
                    type: 'hour',
                    count: 1,
                    text: '1h',
                },
                {
                    type: 'day',
                    count: 1,
                    text: '1D',
                },
                {
                    type: 'week',
                    count: 1,
                    text: '1w',
                },
                {
                    type: 'month',
                    count: 1,
                    text: '1m',
                },
                {
                    type: 'month',
                    count: 3,
                    text: '3m',
                },
                {
                    type: 'all',
                    count: 1,
                    text: 'All',
                },
            ],
            selected: 1,
        },
    };

    // type Area highStock

    const typeAreaCommonConfigs = {
        type: 'area',
        threshold: null,
        fillColor: {
            linearGradient: {
                x1: 0,
                y1: 1,
                x2: 0,
                y2: 0,
            },
            stops: [[0, 'rgb(124, 181, 236, 0.5)'], [1, 'rgb(124, 181, 236, 0)']],
        },
    };

    // type Area highStock

    // type Column highStock

    const typeColumnCommonConfigs = {
        type: 'column',
        threshold: null,
        fillColor: {
            linearGradient: {
                x1: 0,
                y1: 1,
                x2: 0,
                y2: 0,
            },
            stops: [[0, 'rgb(124, 181, 236, 0.5)'], [1, 'rgb(124, 181, 236, 0)']],
        },
    };

    const workLoadTimeLines = {
        title: {
            text: t('statistics.workLoadTimeLine'),
        },
        yAxis: [
            {
                min: 0,
            },
        ],
        ...rangeSelector,
        series: [
            {
                data: workLoadTimeLine,
                name: t('statistics.wasWorking'),
                color: 'rgb(124, 181, 236)',
                ...typeColumnCommonConfigs,
            },
        ],
    };

    const lifeCycleTimelines = {
        title: {
            text: t('statistics.lifeCycleTimeline'),
        },
        yAxis: [
            {
                min: 0,
            },
        ],
        ...rangeSelector,
        series: [
            {
                data: lifeCycleTimeLine,
                name: t('statistics.wasEnabled'),
                color: 'rgb(124, 181, 236)',
                ...typeColumnCommonConfigs,
            },
        ],
    };

    // type Column highStock

    // type two line area

    const twoLinesCommonConfig = {
        plotOptions: {
            series: {
                showInNavigator: true,
            },
        },
    };

    const prepearedVolume = {
        title: {
            text: t('statistics.prepearedVolume'),
        },
        ...twoLinesCommonConfig,
        ...rangeSelector,
        series: [
            {
                name: t('generals.sell'),
                color: '#ff455c',
                data: prepearedVolumeSell,
                ...typeAreaCommonConfigs,
            },
            {
                name: t('generals.buy'),
                color: '#14d999',
                data: prepearedVolumeBuy,
                ...typeAreaCommonConfigs,
            },
        ],
    };

    const sentVolume = {
        title: {
            text: t('statistics.sentVolume'),
        },
        ...twoLinesCommonConfig,
        ...rangeSelector,
        series: [
            {
                name: t('generals.sell'),
                color: '#ff455c',
                data: sentVolumeSell,
                ...typeAreaCommonConfigs,
            },
            {
                name: t('generals.buy'),
                color: '#14d999',
                data: sentVolumeBuy,
                ...typeAreaCommonConfigs,
            },
        ],
    };

    const accuracyOfPreparedVolume = {
        title: {
            text: t('statistics.accuracyOfPreparedVolume'),
        },

        ...twoLinesCommonConfig,
        ...rangeSelector,
        series: [
            {
                name: t('statistics.configuredVolume'),
                color: '#000000',
                data: configuredVolume,
                ...typeAreaCommonConfigs,
            },
            {
                name: t('generals.sell'),
                color: '#ff455c',
                data: accuracyPreparedVolumeSell,
                ...typeAreaCommonConfigs,
            },
            {
                name: t('generals.buy'),
                color: '#14d999',
                data: accuracyPreparedVolumeBuy,
                ...typeAreaCommonConfigs,
            },
        ],
    };

    const accuracyOfSentVolume = {
        title: {
            text: t('statistics.accuracyOfSentVolume'),
        },

        ...twoLinesCommonConfig,
        ...rangeSelector,
        series: [
            {
                name: t('statistics.configuredVolume'),
                color: '#000000',
                data: configuredVolume,
                ...typeAreaCommonConfigs,
            },
            {
                name: t('generals.sell'),
                color: '#ff455c',
                data: accuracySentVolumeSell,
                ...typeAreaCommonConfigs,
            },
            {
                name: t('generals.buy'),
                color: '#14d999',
                data: accuracySentVolumeBuy,
                ...typeAreaCommonConfigs,
            },
        ],
    };

    // type two line area

    const highchartsOptions = [];

    if (workLoadTimeLine.length > 0) {
        highchartsOptions.push(workLoadTimeLines);
    }

    if (lifeCycleTimeLine.length > 0) {
        highchartsOptions.push(lifeCycleTimelines);
    }

    if (prepearedVolumeSell.length > 0 || prepearedVolumeBuy.length > 0) {
        highchartsOptions.push(prepearedVolume);
    }

    if (sentVolumeSell.length > 0 || sentVolumeBuy.length > 0) {
        highchartsOptions.push(sentVolume);
    }

    if (
        configuredVolume.length > 0
        || accuracyPreparedVolumeSell.length > 0
        || accuracyPreparedVolumeBuy.length > 0
    ) {
        highchartsOptions.push(accuracyOfPreparedVolume);
    }

    if (
        configuredVolume.length > 0
        || accuracySentVolumeSell.length > 0
        || accuracySentVolumeBuy.length > 0
    ) {
        highchartsOptions.push(accuracyOfSentVolume);
    }

    return (
        <div className={style.statisticsWrapper}>
            <div className={style.statisticsWrapper__bot}>
                <p className={style.statisticsWrapper__bot_title}>
                    {t('aside.liquidityProvider')} / {t('generals.statistics')} / {botId}
                </p>

                {highchartsOptions.length > 0 ? (
                    <Fragment>
                        {highchartsOptions.map((item, index) => (
                            <HighchartsReact
                                key={index}
                                highcharts={Highcharts}
                                constructorType="stockChart"
                                options={item}
                            />
                        ))}
                    </Fragment>
                ) : (
                    <p className={style.statisticsWrapper__noData}>
                        {t('statistics.noData', { pair: botId })}
                    </p>
                )}
            </div>
        </div>
    );
};

ProviderStatisticsView.defaultProps = {
    match: {},
    prepearedVolumeSell: [],
    prepearedVolumeBuy: [],
    sentVolumeSell: [],
    sentVolumeBuy: [],
    accuracyPreparedVolumeBuy: [],
    accuracyPreparedVolumeSell: [],
    accuracySentVolumeBuy: [],
    accuracySentVolumeSell: [],
    configuredVolume: [],
    workLoadTimeLine: [],
    lifeCycleTimeLine: [],
};

ProviderStatisticsView.propTypes = {
    match: PropTypes.object,
    prepearedVolumeSell: PropTypes.any,
    prepearedVolumeBuy: PropTypes.any,
    sentVolumeSell: PropTypes.any,
    sentVolumeBuy: PropTypes.any,
    accuracyPreparedVolumeBuy: PropTypes.any,
    accuracyPreparedVolumeSell: PropTypes.any,
    accuracySentVolumeBuy: PropTypes.any,
    accuracySentVolumeSell: PropTypes.any,
    configuredVolume: PropTypes.any,
    workLoadTimeLine: PropTypes.any,
    lifeCycleTimeLine: PropTypes.any,
};

export default ProviderStatisticsView;
