import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { message } from 'antd';

import withGetService from '../../../../hoc/with-get-service';
import withPatchService from '../../../../hoc/with-patch-service';
import withDeleteService from '../../../../hoc/with-delete-service';
import fetchProviderBotsAction from '../../../../../actions/getProviderBot.actions';
import deleteProviderBotAction from '../../../../../actions/deleteBot.actions';
import switchStatusProviderAction from '../../../../../actions/switchStatusProvider.actions';
import stopAllBotsProviderAction from '../../../../../actions/stopAllBotsProvider.action';
import cancelBotOrdersAction from '../../../../../actions/cancelBotOrders.actions';
import cancelAllBotOrdersAction from '../../../../../actions/cancelAllBotOrders.action';
import ProviderBotView from './provider-bot-view';
import { compose } from '../../../../../utils';
import Spinner from '../../../../spinner';
import {
    personalAreaPath,
    providerBotsCreatePath,
    providerBotsEditPath,
    updatefixedratesPath,
    STATUS_BOT_RUNNING,
    STATUS_BOT_STOPPED,
} from '../../../../../constants';

export class ProviderBotContainer extends Component {
    static defaultProps = {
        t: () => {},
        history: {},
        switchStatus: false,
        successDeleteBot: false,
        successStopAllProviderBots: false,
        provederBots: [],
        loading: true,
        error: false,
        disabledStartAllBtn: false,
        fetchProviderBots: () => {},
        fetchDeleteProviderBot: () => {},
        fetchswitchStatusProvider: () => {},
        fetchStopAllBotsProvider: () => {},
        cancelBotOrders: () => {},
    };

    static propTypes = {
        t: PropTypes.func,
        history: PropTypes.object,
        provederBots: PropTypes.arrayOf(PropTypes.object),
        switchStatus: PropTypes.bool,
        successDeleteBot: PropTypes.bool,
        successStopAllProviderBots: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.bool,
        disabledStartAllBtn: PropTypes.bool,
        fetchProviderBots: PropTypes.func,
        fetchDeleteProviderBot: PropTypes.func,
        fetchswitchStatusProvider: PropTypes.func,
        fetchStopAllBotsProvider: PropTypes.func,
        cancelBotOrders: PropTypes.func,
        cancelAllBotOrders: PropTypes.func
    };

    state = {
        areUSureModal: false,
        deleteBtnId: null,
        cancelBtnId: null,
        filterBotsData: null,
        yesBtnAreUSureModalItem: '',
    };

    componentDidMount() {
        const {
            fetchProviderBots,
        } = this.props;
        fetchProviderBots();
        fetchProviderBotsAction();
    }

    componentDidUpdate(prevProps) {
        const {
            fetchProviderBots,
            switchStatus,
            successDeleteBot,
            successStopAllProviderBots,
        } = this.props;
        if (
            switchStatus !== prevProps.switchStatus
            || successDeleteBot !== prevProps.successDeleteBot
            || successStopAllProviderBots !== prevProps.successStopAllProviderBots
        ) {
            fetchProviderBots();
        }
    }

    createNewBot = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${providerBotsCreatePath}`);
    };

    stopAllBots = () => {
        const { areUSureModal } = this.state;
        this.setState({
            yesBtnAreUSureModalItem: 'stopAllBots',
            areUSureModal: !areUSureModal,
        });
    };

    startAllBots = () => {
        const { areUSureModal } = this.state;
        this.setState({
            yesBtnAreUSureModalItem: 'startAllBots',
            areUSureModal: !areUSureModal,
        });
    };

    cancelAllBots = () => {
        const { areUSureModal } = this.state;
        this.setState({
            yesBtnAreUSureModalItem: 'cancelAllBots',
            areUSureModal: !areUSureModal,
        });
    }

    switchAreUSureModal = () => {
        const { areUSureModal } = this.state;
        this.setState({
            areUSureModal: !areUSureModal,
        });
    };

    yesBtnAreUSureModal = () => {
        const {
            fetchDeleteProviderBot,
            fetchswitchStatusProvider,
            fetchStopAllBotsProvider,
            cancelBotOrders,
            cancelAllBotOrders,
            t,
        } = this.props;
        const {
            deleteBtnId,
            cancelBtnId,
            filterBotsData,
            yesBtnAreUSureModalItem,
        } = this.state;
        this.setState({
            areUSureModal: false,
        });

        const stopData = {
            status: 'STOPPED',
        };

        const startData = {
            status: 'RUNNING',
        };

        if (yesBtnAreUSureModalItem === 'deleteProviderBot') {
            fetchDeleteProviderBot(deleteBtnId, t);
        }
        if (yesBtnAreUSureModalItem === 'switchStatusProvider') {
            fetchswitchStatusProvider(filterBotsData, filterBotsData.id, t);
        }
        if (yesBtnAreUSureModalItem === 'stopAllBots') {
            fetchStopAllBotsProvider(stopData, t);
        }

        if (yesBtnAreUSureModalItem === 'startAllBots') {
            fetchStopAllBotsProvider(startData, t);
        }

        if (yesBtnAreUSureModalItem === 'cancelAllBots') {
            console.log('cancell all orders');
            cancelAllBotOrders(t);
        }

        if (yesBtnAreUSureModalItem === 'cancelOrders') {
            cancelBotOrders(cancelBtnId, t);
        }
    };

    switchStatusProvider = id => {
        const { provederBots } = this.props;
        const filterBotsData = provederBots.filter(item => item.id === id);
        const filterBotsDataObject = Object.assign({}, filterBotsData[0]);
        const currentStatus = filterBotsDataObject.status === STATUS_BOT_RUNNING
            ? STATUS_BOT_STOPPED
            : STATUS_BOT_RUNNING;

        this.setState(
            {
                yesBtnAreUSureModalItem: 'switchStatusProvider',
                filterBotsData: {
                    ...filterBotsDataObject,
                    status: currentStatus,
                },
            },
            () => {
                this.switchAreUSureModal();
            },
        );
    };

    deleteProviderBot = id => {
        this.switchAreUSureModal();
        this.setState({
            deleteBtnId: id,
            yesBtnAreUSureModalItem: 'deleteProviderBot',
        });
    };

    cancelOrders = id => {
        this.switchAreUSureModal();
        this.setState({
            cancelBtnId: id,
            yesBtnAreUSureModalItem: 'cancelOrders',
        });
    };

    editProviderBot = id => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${providerBotsEditPath}/${id}`);
    };

    cannotChahgeBot = () => {
        const { t } = this.props;
        message.error(t('createBot.NeedTurnOffBot'), 2);
    }

    render() {
        const { areUSureModal } = this.state;
        const {
            provederBots, loading, error, disabledStartAllBtn,
        } = this.props;

        const hasData = !(loading || error);

        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <ProviderBotView
                botsData={provederBots}
                areUSureModal={areUSureModal}
                disabledStartAllBtn={disabledStartAllBtn}
                stopAllBots={this.stopAllBots}
                startAllBots={this.startAllBots}
                cancelAllBots={this.cancelAllBots}
                createNewBot={this.createNewBot}
                yesBtnAreUSureModal={this.yesBtnAreUSureModal}
                switchAreUSureModal={this.switchAreUSureModal}
                switchStatusProvider={this.switchStatusProvider}
                deleteProviderBot={this.deleteProviderBot}
                cancelOrders={this.cancelOrders}
                editProviderBot={this.editProviderBot}
                cannotChahgeBot={this.cannotChahgeBot}
            />
        ) : null;

        return (
            <Fragment>
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        getProviderBot: { provederBots, error, loading },
        statusProvider: { success: switchStatus },
        deleteProviderBot: { success: successDeleteBot },
        stopAllProviderBots: { success: successStopAllProviderBots, loading: disabledStartAllBtn },
    } = state;

    return {
        provederBots,
        loading,
        error,
        switchStatus,
        successDeleteBot,
        successStopAllProviderBots,
        disabledStartAllBtn,
    };
};

const mapDispatchToProps = (dispatch, { getService, deleteService, patchService }) => bindActionCreators(
    {
        fetchProviderBots: fetchProviderBotsAction(getService),
        fetchDeleteProviderBot: deleteProviderBotAction(deleteService),
        cancelBotOrders: cancelBotOrdersAction(deleteService),
        fetchswitchStatusProvider: switchStatusProviderAction(patchService),
        fetchStopAllBotsProvider: stopAllBotsProviderAction(patchService),
        cancelAllBotOrders: cancelAllBotOrdersAction(deleteService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withDeleteService(),
    withGetService(),
    withPatchService(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(ProviderBotContainer);
