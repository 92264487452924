import { getAllSourcesConstants } from '../constants';

const allSources = (state, action) => {
    if (state === undefined) {
        return {
            allSources: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case getAllSourcesConstants.GET_SOURCES_REQUEST:
        return {
            allSources: [],
            loading: true,
            error: false,
        };

    case getAllSourcesConstants.GET_SOURCES_SUCCESS:
        return {
            allSources: action.payload,
            loading: false,
            error: false,
        };

    case getAllSourcesConstants.GET_SOURCES_FAILURE:
        return {
            allSources: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default allSources;
