import { accuracyPreparedVolumeSell } from '../constants';

const getAccuracyPreparedVolumeSell = (state, action) => {
    if (state === undefined) {
        return {
            accuracyPreparedVolumeSell: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case accuracyPreparedVolumeSell.GET_ACCURACY_PREPEARED_VOLUME_SELL_REQUEST:
        return {
            accuracyPreparedVolumeSell: [],
            loading: true,
            error: false,
        };

    case accuracyPreparedVolumeSell.GET_ACCURACY_PREPEARED_VOLUME_SELL_SUCCESS:
        return {
            accuracyPreparedVolumeSell: action.payload,
            loading: false,
            error: false,
        };

    case accuracyPreparedVolumeSell.GET_ACCURACY_PREPEARED_VOLUME_SELL_FAILURE:
        return {
            accuracyPreparedVolumeSell: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getAccuracyPreparedVolumeSell;
