import { getInrBotConstants } from '../constants/getInrBots.constants';

const getInrProviderBot = (state, action) => {
    if (state === undefined) {
        return {
            inrProvederBots: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case getInrBotConstants.GET_INR_BOT_REQUEST:
        return {
            inrProvederBots: [],
            loading: true,
            error: false,
        };

    case getInrBotConstants.GET_INR_BOT_SUCCESS:
        console.log('Hello');
        return {
            inrProvederBots: action.payload,
            loading: false,
            error: false,
        };

    case getInrBotConstants.GET_INR_BOT_FAILURE:
        return {
            inrProvederBots: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getInrProviderBot;
