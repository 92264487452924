import { message } from 'antd';
import {
    editBotConstants, personalAreaPath,
    providerBotsPath,
} from '../constants';


const editProviderBotRequested = () => ({
    type: editBotConstants.EDIT_BOT_REQUEST,
});

const editProviderBotLoaded = data => ({
    type: editBotConstants.EDIT_BOT_SUCCESS,
    payload: data,
});

const editProviderBotError = error => ({
    type: editBotConstants.EDIT_BOT_FAILURE,
    payload: error,
});

const editProviderBotAction = patchService => (filterBotsdata, id, t, history) => dispatch => {
    dispatch(editProviderBotRequested());
    patchService
        .switchStatusProvider(filterBotsdata, id)
        .then(data => {
            dispatch(editProviderBotLoaded(data));
            message.success(t('createBot.updatedSuccessfully'), 2);
            history.push(`${personalAreaPath}${providerBotsPath}`);
        })
        .catch(err => {
            dispatch(editProviderBotError(err));
        });
};

export default editProviderBotAction;
