import { message } from 'antd';

const cancelAllBotsOrdersAction = deleteService => (t) => dispatch => {
    deleteService
        .cancelAllBotOrders()
        .then(() => {
            message.success(t('generals.success'), 2);
        })
        .catch(err => {
            message.error(err.response.data.message, 2);
        });
};

export default cancelAllBotsOrdersAction;
