import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '../button';
import { STATUS_BOT_RUNNING, STATUS_ENABLED } from '../../../constants';
import style from './bot-table-row.module.scss';

const BotTableRow = props => {
    const {
        botsData,
        switchStatusProvider,
        cancelOrders,
        editProviderBot,
        cannotChahgeBot,
        isShowDailyVolume,
        isCancelOrders,
        deleteProviderBot,
    } = props;
    const { t } = useTranslation();

    return (
        <Fragment>
            {botsData.length < 1 ? (
                <p className={style.botTable__noBots}>{t('createBot.noBots')}</p>
            ) : (
                <Fragment>
                    {botsData.map((item, index) => {
                        const { id, volumePerDay, status } = item;

                        const isEnabledStatus = [STATUS_BOT_RUNNING, STATUS_ENABLED];

                        const statusStyle = isEnabledStatus.includes(status)
                            ? style.botTable__status_green
                            : style.botTable__status_red;

                        return (
                            <Fragment key={id}>
                                <div className={style.botTable}>
                                    <div className={style.botTable__id}>
                                        <p className={style.botTable__label}>Id</p>
                                        <p className={style.botTable__id_value}>
                                            {index + 1}
                                        </p>
                                    </div>
                                    <div className={style.botTable__pair}>
                                        <p className={style.botTable__label}>
                                            {t('generals.pair')}
                                        </p>
                                        <p className={style.botTable__pair_value}>{id}</p>
                                    </div>
                                    {isShowDailyVolume
                                        ? (
                                            <div className={style.botTable__dailyVolume}>
                                                <p className={style.botTable__label}>
                                                    {t('generals.dailyVolume')}
                                                </p>
                                                <p className={style.botTable__dailyVolume_value}>
                                                    {volumePerDay}
                                                </p>
                                            </div>
                                        )
                                        : null}
                                    <div className={style.botTable__status}>
                                        <p className={style.botTable__label}>
                                            {t('generals.status')}
                                        </p>
                                        <p className={statusStyle}>{!status ? 'DISABLED' : status}</p>
                                    </div>
                                    <div className={style.botTable__buttonsWrapper}>
                                        {isEnabledStatus.includes(status) ? (
                                            <Button
                                                type="button"
                                                className={style.botTable__stopBtn}
                                                onClick={() => switchStatusProvider(id)}
                                            >
                                                {t('generals.stop')}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="button"
                                                className={style.botTable__editBtn}
                                                onClick={() => switchStatusProvider(id)}
                                            >
                                                {t('generals.start')}
                                            </Button>
                                        )}
                                        <Button
                                            type="button"
                                            className={style.botTable__deleteBtn}
                                            onClick={
                                                isEnabledStatus.includes(status)
                                                    ? cannotChahgeBot
                                                    : () => deleteProviderBot(id)
                                            }
                                        >
                                            {t('generals.delete')}
                                        </Button>
                                        <Button
                                            type="button"
                                            className={style.botTable__editBtn}
                                            onClick={status === STATUS_BOT_RUNNING
                                                ? cannotChahgeBot
                                                : () => editProviderBot(id)
                                            }
                                        >
                                            {t('generals.edit')}
                                        </Button>
                                        {isCancelOrders
                                            ? (
                                                <Button
                                                    type="button"
                                                    className={style.botTable__deleteBtn}
                                                    onClick={
                                                        isEnabledStatus.includes(status)
                                                            ? cannotChahgeBot
                                                            : () => cancelOrders(id)
                                                    }
                                                >
                                                    {t('generals.cancelOrders')}
                                                </Button>
                                            )
                                            : null}
                                    </div>
                                </div>
                            </Fragment>
                        );
                    })}
                </Fragment>
            )}
        </Fragment>
    );
};

BotTableRow.defaultProps = {
    botsData: [],
    switchStatusProvider: () => {},
    cancelOrders: () => {},
    editProviderBot: () => {},
    cannotChahgeBot: () => {},
    deleteProviderBot: () => {},
    isShowDailyVolume: true,
    isCancelOrders: false,
};

BotTableRow.propTypes = {
    botsData: PropTypes.arrayOf(PropTypes.object),
    switchStatusProvider: PropTypes.func,
    cancelOrders: PropTypes.func,
    editProviderBot: PropTypes.func,
    cannotChahgeBot: PropTypes.func,
    deleteProviderBot: PropTypes.func,
    isShowDailyVolume: PropTypes.bool,
    isCancelOrders: PropTypes.bool,
};

export default BotTableRow;
