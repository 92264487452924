import { message } from 'antd';
import { makeOrderConstants } from '../constants';

const makeOrderRequested = () => ({
    type: makeOrderConstants.FETCH_MAKE_ORDER_REQUEST,
});

const makeOrderLoaded = (data, id) => ({
    type: makeOrderConstants.FETCH_MAKE_ORDER_SUCCESS,
    payload: data,
    id,
});

const makeOrderError = error => ({
    type: makeOrderConstants.FETCH_MAKE_ORDER_FAILURE,
    payload: error,
});

const makeOrderAction = PatchService => (pairName, data, t, id) => dispatch => {
    dispatch(makeOrderRequested());
    PatchService
        .makeOrder(pairName, data)
        .then(item => {
            dispatch(makeOrderLoaded(item, id));

            item.map(items => {
                const { error, message: messageError } = items;
                if (error) {
                    message.error(messageError, 3);
                } else {
                    message.success(t('generals.success'), 4);
                }
                return true;
            });
        })
        .catch(err => {
            dispatch(makeOrderError(err));
        });
};

export default makeOrderAction;
