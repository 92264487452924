const STATUS_BOT_STOPPED = 'STOPPED';
const STATUS_BOT_RUNNING = 'RUNNING';
const STATUS_ENABLED = 'ENABLED';
const STATUS_DISABLED = 'DISABLED';

export {
    STATUS_BOT_STOPPED,
    STATUS_BOT_RUNNING,
    STATUS_ENABLED,
    STATUS_DISABLED,
};
