const personalAreaPath = '/personal-area';
const providerBotsPath = '/liquidity-provider-bots';
const InrBotsPath = '/Inr-Bots';
const providerBotsCreatePath = '/liquidity-provider-create-bot';
const providerBotsEditPath = '/liquidity-provider-edit-bot';
const providerBotsStatistics = '/liquidity-provider-statistics';
const ordersPath = '/orders';
const createNewOrderPath = '/create-new-order';
const editOrderPath = '/edit-order';

export {
    personalAreaPath,
    providerBotsPath,
    providerBotsCreatePath,
    InrBotsPath,
    providerBotsEditPath,
    providerBotsStatistics,
    ordersPath,
    createNewOrderPath,
    editOrderPath,
};
