// import { message } from 'antd';
import * as moment from 'moment';
import axios from 'axios';
import { setStatus, resetStatus } from '../reducers/jwt.reducer';
import { userActions } from '../actions';

function logout() {
    localStorage.removeItem('user');
}

const userJson = JSON.parse(localStorage.getItem('user'));

const getToken = () => userJson && userJson.data && userJson.data.refresh_token;

if (localStorage.getItem('user') === 'undefined') {
    logout();
} else if (userJson && !userJson.data) {
    logout();
}

function login(username, password) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('grant_type', 'password');

    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Basic YnJvd3Nlcjpicm93c2Vy',
            'Access-Control-Allow-Origin': '*',
        },
        data: formData,
        url: `${process.env.REACT_APP_API_URL_AUTH_SERVICE}/oauth/token`,
    };

    return axios(options)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('updateTokenTime', moment().unix());
            return user;
        });
}

function refreshToken(dispatch) {
    const formData = new FormData();
    formData.append('refresh_token', getToken());
    formData.append('grant_type', 'refresh_token');

    const options = {
        method: 'POST',
        headers: {
            Authorization: 'Basic YnJvd3Nlcjpicm93c2Vy',
            'Access-Control-Allow-Origin': '*',
        },
        data: formData,
        url: `${process.env.REACT_APP_API_URL_AUTH_SERVICE}/oauth/token`,
    };
    const response = axios(options);

    response
        .then(user => {
            dispatch(resetStatus());
            dispatch(userActions.success(user));
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('updateTokenTime', moment().unix());

            return user.data.access_token
                ? Promise.resolve(dispatch(setStatus(null)))
                : Promise.reject(dispatch(setStatus(null)));
        })
        .catch(e => {
            if (e.response.data.error === 'invalid_grant') {
                localStorage.removeItem('user');
                localStorage.removeItem('updateTokenTime');
                document.location.reload(true);
            }
            dispatch(resetStatus());
            return Promise.reject(e);
        });

    dispatch(setStatus(response));
    return response;
}

export const userService = {
    login,
    logout,
};

export default refreshToken;
