import { prepearedVolumeBuy } from '../constants';

const getPrepearedVolumeBuy = (state, action) => {
    if (state === undefined) {
        return {
            getPrepearedVolumeBuy: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case prepearedVolumeBuy.GET_PREPEARED_VOLUME_BUY_REQUEST:
        return {
            getPrepearedVolumeBuy: [],
            loading: true,
            error: false,
        };

    case prepearedVolumeBuy.GET_PREPEARED_VOLUME_BUY_SUCCESS:
        return {
            getPrepearedVolumeBuy: action.payload,
            loading: false,
            error: false,
        };

    case prepearedVolumeBuy.GET_PREPEARED_VOLUME_BUY_FAILURE:
        return {
            getPrepearedVolumeBuy: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getPrepearedVolumeBuy;
