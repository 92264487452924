import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import withPostService from '../../../../hoc/with-post-service';
import withGetService from '../../../../hoc/with-get-service';
import withPatchService from '../../../../hoc/with-patch-service';
import { compose } from '../../../../../utils';
import EditOrderView from './edit-order-view';
import ErrorIndicator from '../../../error-page/error-indicator';
import fetchTargetPairsAction from '../../../../../actions/getTargetPairs.actions';
import getOneOrderAction from '../../../../../actions/getOneOrder.actions';
import updateScheduledOrderAction from '../../../../../actions/patchScheduledOrder.actions';

export class EditOrderContainer extends Component {
    state = {
        errors: {},
        scheduledOrdersData: {},
    };

    componentDidMount() {
        const {
            fetchTargetPairs,
            getOneOrder,
            match: {
                params: { id },
            },
        } = this.props;
        fetchTargetPairs();
        getOneOrder(id);
    }

    componentDidUpdate(prevProps) {
        const {
            getOneOrder,
            successCancelOrder,
            successgetOneOrder,
            successUpdateScheduledOrder,
            match: {
                params: { id },
            },
        } = this.props;
        if (successgetOneOrder && successgetOneOrder !== prevProps.successgetOneOrder) {
            const {
                getOneOrderData,
                getOneOrderData: {
                    pair: { left, right },
                },
            } = this.props;
            this.setState({
                scheduledOrdersData: {
                    ...getOneOrderData,
                    pairName: `${left}-${right}`,
                },
            });
        }

        if (successCancelOrder && successCancelOrder !== prevProps.successCancelOrder) {
            getOneOrder(id);
        }

        if (successUpdateScheduledOrder && successUpdateScheduledOrder !== prevProps.successUpdateScheduledOrder) {
            this.setState({
                errors: {},
            });
        }
    }

    // save input changes to state
    inputOnchange = event => {
        const { name, value } = event.target;
        const { scheduledOrdersData } = this.state;

        this.setState({
            scheduledOrdersData: {
                ...scheduledOrdersData,
                [name]: value,
            },
        });
    };

    // save select changes to state
    selectOnChange = (value, name) => {
        this.setState({
            [name.props.name]: value,
        });
    };

    update = () => {
        const { t, updateScheduledOrder } = this.props;
        const {
            scheduledOrdersData: {
                buySellCoef,
                frequencyMax,
                frequencyMin,
                ordersQuantityMax,
                ordersQuantityMin,
                minOrderVolume,
                maxOrderVolume,
                userId,
                buyValueMin,
                buyValueMax,
                volumeLimit,
            },
        } = this.state;
        const errors = {};

        if (userId.length < 1) {
            errors.userIdError = t('error.input', { count: 1 });
        }

        if (buySellCoef.length < 1) {
            errors.buySellCoefError = t('error.input', { count: 1 });
        }

        if (volumeLimit.length < 1) {
            errors.volumeLimitError = t('error.input', { count: 1 });
        }

        if (buyValueMin.length < 1) {
            errors.buyValueMinError = t('error.input', { count: 1 });
        }

        if (buyValueMax.length < 1) {
            errors.buyValueMaxError = t('error.input', { count: 1 });
        }

        if (frequencyMin.length < 1) {
            errors.frequencyMinError = t('error.input', { count: 1 });
        }

        if (frequencyMax.length < 1) {
            errors.frequencyMaxError = t('error.input', { count: 1 });
        }

        if (ordersQuantityMin.length < 1) {
            errors.ordersQuantityMinError = t('error.input', { count: 1 });
        }

        if (ordersQuantityMax.length < 1) {
            errors.ordersQuantityMaxError = t('error.input', { count: 1 });
        }

        if (minOrderVolume.length < 1) {
            errors.minOrderVolumeError = t('error.input', { count: 1 });
        }

        if (maxOrderVolume.length < 1) {
            errors.maxOrderVolumeError = t('error.input', { count: 1 });
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
            message.error(t('general.errorFields'), 2);
        } else {
            const { scheduledOrdersData } = this.state;
            updateScheduledOrder(scheduledOrdersData, t);
        }
    };

    onError = () => {
        this.setState({
            error: true,
        });
    };

    toggleCheckbox = (event, name) => {
        const { checked } = event.target;
        const { scheduledOrdersData } = this.state;
        this.setState({
            scheduledOrdersData: {
                ...scheduledOrdersData,
                [name]: checked,
            },
        }, () => {
            this.update();
        });
    }

    render() {
        const {
            error,
            errors: {
                userIdError,
                buyValueMinError,
                buyValueMaxError,
                frequencyMinError,
                frequencyMaxError,
                volumeLimitError,
                buySellCoefError,
                ordersQuantityMinError,
                ordersQuantityMaxError,
                minOrderVolumeError,
                maxOrderVolumeError,
            },
            scheduledOrdersData,
        } = this.state;
        const { targetPairs, loading } = this.props;

        const hasData = !(error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const content = hasData ? (
            <EditOrderView
                targetPairs={targetPairs}
                inputOnchange={this.inputOnchange}
                update={this.update}
                toggleCheckbox={this.toggleCheckbox}
                buyValueMinError={buyValueMinError}
                buyValueMaxError={buyValueMaxError}
                frequencyMinError={frequencyMinError}
                frequencyMaxError={frequencyMaxError}
                volumeLimitError={volumeLimitError}
                minOrderVolumeError={minOrderVolumeError}
                maxOrderVolumeError={maxOrderVolumeError}
                userIdError={userIdError}
                buySellCoefError={buySellCoefError}
                ordersQuantityMinError={ordersQuantityMinError}
                ordersQuantityMaxError={ordersQuantityMaxError}
                scheduledOrdersData={scheduledOrdersData}
                loading={loading}
            />
        ) : null;
        return (
            <Fragment>
                {errorMessage}
                {content}
            </Fragment>
        );
    }
}

EditOrderContainer.defaultProps = {
    t: () => {},
    fetchTargetPairs: () => {},
    getOneOrder: () => {},
    updateScheduledOrder: () => {},
    targetPairs: [],
    successgetOneOrder: false,
    loading: true,
    successCancelOrder: false,
    getOneOrderData: {},
    match: {},
    successUpdateScheduledOrder: false,
};

EditOrderContainer.propTypes = {
    t: PropTypes.func,
    fetchTargetPairs: PropTypes.func,
    getOneOrder: PropTypes.func,
    updateScheduledOrder: PropTypes.func,
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    successgetOneOrder: PropTypes.bool,
    loading: PropTypes.bool,
    getOneOrderData: PropTypes.object,
    match: PropTypes.object,
    successCancelOrder: PropTypes.bool,
    successUpdateScheduledOrder: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        targetPairs: { targetPairs },
        updateScheduledOrder: { data: updateScheduledOrderData, success: successUpdateScheduledOrder },
        getOneOrder: { success: successgetOneOrder, data: getOneOrderData, loading },
        cancelOrder: { success: successCancelOrder },
    } = state;

    return {
        targetPairs,
        getOneOrderData,
        successgetOneOrder,
        successUpdateScheduledOrder,
        updateScheduledOrderData,
        loading,
        successCancelOrder,
    };
};

const mapDispatchToProps = (dispatch, { getService, patchService }) => bindActionCreators(
    {
        fetchTargetPairs: fetchTargetPairsAction(getService),
        getOneOrder: getOneOrderAction(getService),
        updateScheduledOrder: updateScheduledOrderAction(patchService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withPatchService(),
    withGetService(),
    withPostService(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(EditOrderContainer);
