import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../../../../UI/button';
import GreenRedButtonBlock from '../../../../UI/green-red-button-block';
import BotTableRow from '../../../../UI/bot-table-row';
import AreUSureModal from '../../../../UI/are-u-sure-modal';

import style from './provider-bot-page.module.scss';

const ProviderBotView = ({
    botsData,
    areUSureModal,
    createNewBot,
    stopAllBots,
    yesBtnAreUSureModal,
    switchAreUSureModal,
    switchStatusProvider,
    deleteProviderBot,
    cancelOrders,
    editProviderBot,
    cannotChahgeBot,
    startAllBots,
    cancelAllBots,
    updatefixedrates,
    disabledStartAllBtn,
}) => {
    const { t } = useTranslation();

    return (
        <div className={style.botsWrapper}>
            <div className={style.botsWrapper__bot}>
                <p className={style.botsWrapper__bot_title}>
                    {t('aside.liquidityProvider')} / {t('aside.bots')}
                </p>
                <div className={style.buttonsWrapper}>
                    <GreenRedButtonBlock
                        greenRedButtonWrapper={style.greenRedButtonWrapper}
                        botsData={botsData}
                        hideIfLessOne
                        clickGreenBtn={createNewBot}
                        clickRedBtn={stopAllBots}
                        greenBtnText={t('generals.createNew')}
                        redBtnText={t('generals.stopAll')}
                    />
                    <Button
                        onClick={startAllBots}
                        className={style.startAllBotsBtn}
                        type="button"
                        disabled={disabledStartAllBtn}
                    >
                        {t('generals.startAll')}
                    </Button>
                    <Button
                        onClick={updatefixedrates}
                        className={style.startAllBotsBtn}
                        type="button"
                        disabled={disabledStartAllBtn}
                    >
                        {t('generals.resetAll')}
                    </Button>
                    <Button
                        onClick={cancelAllBots}
                        className={style.cancelAllBotsBtn}
                        type="button"
                        disabled={disabledStartAllBtn}
                    >
                        {t('generals.cancelAll')}
                    </Button>
                </div>
                <BotTableRow
                    botsData={botsData}
                    switchStatusProvider={switchStatusProvider}
                    deleteProviderBot={deleteProviderBot}
                    editProviderBot={editProviderBot}
                    cannotChahgeBot={cannotChahgeBot}
                    cancelOrders={cancelOrders}
                    isCancelOrders
                />
            </div>
            <AreUSureModal
                visibleModal={areUSureModal}
                noButton={switchAreUSureModal}
                yesButton={yesBtnAreUSureModal}
            />
        </div>
    );
};

ProviderBotView.defaultProps = {
    botsData: [],
    areUSureModal: false,
    disabledStartAllBtn: false,
    startAllBots: () => {},
    updatefixedrates: () => {},
    stopAllBots: () => {},
    cancelAllBots: () => {},
    createNewBot: () => {},
    switchStatusProvider: () => {},
    deleteProviderBot: () => {},
    cancelOrders: () => {},
    editProviderBot: () => {},
    yesBtnAreUSureModal: () => {},
    switchAreUSureModal: () => {},
    cannotChahgeBot: () => {},
};

ProviderBotView.propTypes = {
    botsData: PropTypes.arrayOf(PropTypes.object),
    areUSureModal: PropTypes.bool,
    disabledStartAllBtn: PropTypes.bool,
    startAllBots: PropTypes.func,
    cancelAllBots: PropTypes.func,
    updatefixedrates: PropTypes.func,
    stopAllBots: PropTypes.func,
    createNewBot: PropTypes.func,
    switchStatusProvider: PropTypes.func,
    deleteProviderBot: PropTypes.func,
    cancelOrders: PropTypes.func,
    editProviderBot: PropTypes.func,
    yesBtnAreUSureModal: PropTypes.func,
    switchAreUSureModal: PropTypes.func,
    cannotChahgeBot: PropTypes.func,
};

export default ProviderBotView;
