import { workLoadTimeLine } from '../constants';

const getWorkLoadTimeLine = (state, action) => {
    if (state === undefined) {
        return {
            workLoadTimeLine: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case workLoadTimeLine.WORK_LOAD_TIME_LINE_REQUEST:
        return {
            workLoadTimeLine: [],
            loading: true,
            error: false,
        };

    case workLoadTimeLine.WORK_LOAD_TIME_LINE_SUCCESS:
        return {
            workLoadTimeLine: action.payload,
            loading: false,
            error: false,
        };

    case workLoadTimeLine.WORK_LOAD_TIME_LINE_FAILURE:
        return {
            workLoadTimeLine: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getWorkLoadTimeLine;
