import { switchStatusConstants } from '../constants';

const statusGenerator = (state, action) => {
    if (state === undefined) {
        return {
            switchStatusGenerator: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
    case switchStatusConstants.SWITCH_STATUS_REQUEST:
        return {
            switchStatusGenerator: {},
            loading: true,
            error: null,
            success: false,
        };

    case switchStatusConstants.SWITCH_STATUS_SUCCESS:
        return {
            switchStatusGenerator: action.payload,
            loading: false,
            error: null,
            success: true,
        };

    case switchStatusConstants.SWITCH_STATUS_FAILURE:
        return {
            switchStatusGenerator: {},
            loading: false,
            error: action.payload,
            success: false,
        };

    default:
        return state;
    }
};

export default statusGenerator;
