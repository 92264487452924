import { getBotConstants } from '../constants';

const getGeneratorBot = (state, action) => {
    if (state === undefined) {
        return {
            generatorBots: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case getBotConstants.GET_BOT_REQUEST:
        return {
            generatorBots: [],
            loading: true,
            error: false,
        };

    case getBotConstants.GET_BOT_SUCCESS:
        return {
            generatorBots: action.payload,
            loading: false,
            error: false,
        };

    case getBotConstants.GET_BOT_FAILURE:
        return {
            generatorBots: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getGeneratorBot;
