import React from 'react';
import { PatchServiceConsumer } from '../../context/patch-service-context';

const withPatchService = () => Wrapped => props => (
    <PatchServiceConsumer>
        {
            patchService => (
                <Wrapped
                    {...props}
                    patchService={patchService}
                />
            )
        }
    </PatchServiceConsumer>
);

export default withPatchService;
