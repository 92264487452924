import { message } from 'antd';
import axios from 'axios';

export default class DeleteService {
    getResource = async (url, data) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const getToken = () => user.data.access_token;

        const options = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
            data,
            url: `${process.env.REACT_APP_API_URL_PROVIDER}${url}`,
        };
        const response = await axios(options);

        if (response.status === 401) {
            this.logout();
        }

        if (response.status !== 200) {
            response.json().then(errorMessage => {
                message.error(
                    errorMessage.message
                        ? errorMessage.message
                        : errorMessage.error_description,
                    2,
                );
            });
        }

        return response.data;
    };

    logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('updateTokenTime');
    };

    deleteProviderBot = async id => {
        const res = await this.getResource(`/bot-configs/${id}`);
        return res;
    };

    cancelBotOrders= async pair => {
        const res = await this.getResource(`/bot-configs/${pair}/orders`);
        return res;
    };

    cancelAllBotOrders = async () => {
        const res = await this.getResource('/bot-configs/cancelAll');
        return res;
    };

    deleteGeneratorBot = async id => {
        const res = await this.getResource(`/liquidity/marketmaker/${id}`);
        return res;
    };

    cancelOrder = async (pairName, data) => {
        const res = await this.getResource(`/orders/manual/${pairName}`, data);
        return res;
    };

    deleteOrder = async pairName => {
        const res = await this.getResource(`/orders/${pairName}`);
        return res;
    };
}
