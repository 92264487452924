import { message } from 'antd';
import {
    createBot, personalAreaPath,
    providerBotsPath,
} from '../constants';

const createProviderBotRequested = () => ({
    type: createBot.FETCH_CREATE_BOT_REQUEST,
});

const createProviderBotLoaded = data => ({
    type: createBot.FETCH_CREATE_BOT_SUCCESS,
    payload: data,
});

const createProviderBotError = error => ({
    type: createBot.FETCH_CREATE_BOT_FAILURE,
    payload: error,
});

const fetchCreateProviderBotAction = postService => (createNewBot, t, history) => dispatch => {
    dispatch(createProviderBotRequested());
    postService
        .createProviderBot(createNewBot)
        .then(data => {
            dispatch(createProviderBotLoaded(data));
            message.success(t('createBot.successCreated'), 2);
            history.push(`${personalAreaPath}${providerBotsPath}`);
        })
        .catch(err => {
            dispatch(createProviderBotError(err));
            message.error(err.message, 2);
        });
};

export default fetchCreateProviderBotAction;
