import { UPDATE_ORDER_STATUS } from '../constants';

const updateOrderStatus = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
    case UPDATE_ORDER_STATUS.UPDATE_ORDER_STATUS_REQUEST:
        return {
            data: {},
            loading: true,
            error: null,
            success: false,
        };

    case UPDATE_ORDER_STATUS.UPDATE_ORDER_STATUS_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: null,
            success: true,
        };

    case UPDATE_ORDER_STATUS.UPDATE_ORDER_STATUS_FAILURE:
        return {
            data: {},
            loading: false,
            error: action.payload,
            success: false,
        };

    default:
        return state;
    }
};

export default updateOrderStatus;
