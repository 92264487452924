const createProviderBotInputs = [
    {
        value: 'frequencySeconds',
        label: 'createBot.setFrequencyOrderbookRefreshing',
        error: 'frequencySecondsError',
        type: 'number',
    },
    {
        value: 'pricePercents',
        label: 'createBot.set%MarginPrice',
        error: 'pricePercentsError',
        type: 'number',
    },
    {
        value: 'minOrderAmount',
        label: 'createBot.minOrderAmount',
        error: 'minOrderAmountError',
        type: 'number',
    },
    {
        value: 'maxOrderAmount',
        label: 'createBot.maxOrderAmount',
        error: 'maxOrderAmountError',
        type: 'number',
    },
    {
        value: 'minPercentBetweenOrdersPrice',
        label: 'createBot.minPercentBetweenOrdersPrice',
        error: 'minPercentBetweenOrdersPriceError',
        type: 'number',
    },
    {
        value: 'maxPercentBetweenOrdersPrice',
        label: 'createBot.maxPercentBetweenOrdersPrice',
        error: 'maxPercentBetweenOrdersPriceError',
        type: 'number',
    },
    {
        value: 'orderBookDepth',
        label: 'createBot.setDepthOrderBook',
        error: 'orderBookDepthError',
        type: 'number',
    },
    {
        value: 'volumePerDay',
        label: 'createBot.setVolumeTradedperDay',
        error: 'volumePerDayError',
        type: 'number',
    },
    {
        value: 'quantityPerOrderPercent',
        label: 'createBot.setMaxQuantityOrderIn%',
        error: 'quantityPerOrderPercentError',
        type: 'number',
    },
    {
        value: 'minOrderQuantity',
        label: 'createBot.minOrderQuantity',
        error: 'minOrderQuantityError',
        type: 'number',
    },
    {
        value: 'amountLimitFromSource',
        label: 'createBot.amountLimitFromSource',
        error: 'amountLimitFromSourceError',
        type: 'number',
    },
    {
        value: 'minTimeActivity',
        label: 'createBot.minTimeActivity',
        error: 'minTimeActivityError',
        type: 'number',
    },
    {
        value: 'maxTimeActivity',
        label: 'createBot.maxTimeActivity',
        error: 'maxTimeActivityError',
        type: 'number',
    },
    {
        value: 'userId',
        label: 'createBot.userId',
        error: 'userIdError',
        type: 'number',
    },
];

export default createProviderBotInputs;
