import { GET_CURRENCIES_ALL } from '../constants';

const currenciesAll = (state, action) => {
    if (state === undefined) {
        return {
            data: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case GET_CURRENCIES_ALL.GET_CURRENCIES_ALL_REQUEST:
        return {
            data: [],
            loading: true,
            error: false,
        };

    case GET_CURRENCIES_ALL.GET_CURRENCIES_ALL_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
        };

    case GET_CURRENCIES_ALL.GET_CURRENCIES_ALL_FAILURE:
        return {
            data: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default currenciesAll;
