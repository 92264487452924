import { getAllCoinsConstants } from '../constants';

const allCoins = (state, action) => {
    if (state === undefined) {
        return {
            allCoins: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case getAllCoinsConstants.GET_COINS_REQUEST:
        return {
            allCoins: [],
            loading: true,
            error: false,
        };

    case getAllCoinsConstants.GET_COINS_SUCCESS:
        return {
            allCoins: action.payload,
            loading: false,
            error: false,
        };

    case getAllCoinsConstants.GET_COINS_FAILURE:
        return {
            allCoins: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default allCoins;
