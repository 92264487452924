import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Burger from 'react-css-burger';
import SelectLanguage from '../../language';
import { compose } from '../../../utils';
import { userActions } from '../../../actions';
import Aside from '../../pages/personal-area/personal-area-view/aside';
import logo from './images/bot.svg';
import style from './header.module.scss';
import './header.scss';
import 'antd/dist/antd.css';


export class Header extends Component {
    static defaultProps = {
        location: {},
        loggedIn: false,
    };

    static propTypes = {
        location: PropTypes.object,
        loggedIn: PropTypes.bool,
    };

    state = {
        activeBurger: false,
    }

    componentDidMount() {
        this.timeOut = setTimeout(() => {
            document.body.classList.add('domLoaded');
        }, 100);
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname } } = this.props;

        if (pathname !== prevProps.location.pathname) {
            this.closeMenu();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeOut);
    }

    toggleBurger = () => {
        this.setState(state => ({
            activeBurger: !state.activeBurger,
        }));
    }

    closeMenu = () => {
        this.setState({
            activeBurger: false,
        });
    }

    render() {
        const { location: { pathname }, loggedIn } = this.props;
        const { activeBurger } = this.state;

        const headerStyles = classNames(
            style.header,
            style.headerCabinet,
        );

        console.log(this.props);

        const changeHeader = pathname === '/' ? style.header : headerStyles;

        return (
            <Fragment>
                <header id="header" className={changeHeader}>
                    <div className={style.header__leftSide}>
                        <div className="animationLogo">
                            <Link to="/" className={style.header__logo}>
                                <img src={logo} alt="logo" />
                                    Liquidity Bot
                            </Link>
                        </div>
                    </div>
                    {loggedIn
                        ? (
                            <div className={style.header__rightSide}>
                                <div className={style.header__burgerMenu}>
                                    <Burger
                                        onClick={this.toggleBurger}
                                        active={activeBurger}
                                        burger="spin"
                                        color="#007aff"
                                        marginTop="0"
                                        scale={0.65}
                                    />
                                </div>
                                <div className="animationSelectLang mobileMenu__selectLanguage">
                                    <SelectLanguage />
                                </div>
                            </div>
                        )
                        : null}
                    <Drawer
                        title="Basic Drawer"
                        placement="left"
                        closable={false}
                        onClose={this.toggleBurger}
                        visible={activeBurger}
                        key="left"
                    >
                        <Aside />
                    </Drawer>
                </header>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

const mapDispatchToProps = dispatch => ({
    logOut() {
        dispatch(userActions.logout());
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(Header);
