import { message } from 'antd';
import axios from 'axios';

export default class PatchService {
    getResource = async (url, data) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const getToken = () => user.data.access_token;

        const options = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
            data,
            url: `${process.env.REACT_APP_API_URL_PROVIDER}${url}`,
        };
        const response = await axios(options);

        if (response.status === 401) {
            this.logout();
        }

        if (response.status !== 200) {
            response.json().then(errorMessage => {
                message.error(
                    errorMessage.message
                        ? errorMessage.message
                        : errorMessage.error_description,
                    2,
                );
            });
        }

        return response.data;
    };

    logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('updateTokenTime');
        document.location.reload(true);
    };

    switchStatusProvider = async (data, id) => {
        const res = await this.getResource(`/bot-configs/${id}`, data);
        return res;
    };

    switchStatusGenerator = async (pairName, command) => {
        const res = await this.getResource(`/liquidity/marketmaker/${pairName}/${command}`);
        return res;
    };

    stopAllProvider = async data => {
        const res = await this.getResource('/bot-configs/', data);
        return res;
    };

    updateScheduledOrder = async data => {
        const res = await this.getResource('/orders', data);
        return res;
    };

    updateOrderStatus = async (pairName, status) => {
        const res = await this.getResource(`/orders/status/${pairName}/${status}`);
        return res;
    };

    makeOrder = async (pairName, data) => {
        const res = await this.getResource(`/orders/${pairName}`, data);
        return res;
    };
}
