import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from '../../UI/field';
import { compose } from '../../../utils';
import Button from '../../UI/button';
import { userActions } from '../../../actions';

import eye from '../../assets/images/eye.svg';
import style from './login.module.scss';

class Login extends Component {
    state = {
        username: '',
        password: '',
        type: 'password',
        errors: {
            usernameError: '',
            passwordError: '',
        },
    };

    InputOnchange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    LoginSubmit = event => {
        event.preventDefault();
        const { t, dispatch, history } = this.props;
        const { username, password } = this.state;
        const errors = {};

        if (username.length < 4) {
            errors.usernameError = t('error.input', { count: 4 });
        }

        if (password.length < 5) {
            errors.passwordError = t('error.input', { count: 5 });
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            if (username && password) {
                dispatch(userActions.login(username, password, history));
            }
        }
    };

    showHidePassword = () => {
        const { type } = this.state;
        this.setState({
            type: type === 'password' ? 'text' : 'password',
        });
    };

    render() {
        const { t, authLoading } = this.props;
        const {
            errors: { usernameError, passwordError },
            username,
            password,
            type,
        } = this.state;
        return (
            <div className={style.login}>
                <h2 className={style.login__title}>{t('auth.signIn')}</h2>
                <form onSubmit={this.LoginSubmit}>
                    <div className={style.login__inputWrapper}>
                        <Field
                            id="usernameLogin"
                            type="username"
                            placeholder={t('general.username')}
                            name="username"
                            value={username}
                            onChange={this.InputOnchange}
                            error={usernameError}
                            inputStyle={style.login__input}
                            labelText={t('general.username')}
                            labelStyle={style.login__label}
                        />
                    </div>
                    <div className={style.login__inputWrapper}>
                        <Field
                            id="password"
                            type={type}
                            placeholder={t('form.password')}
                            name="password"
                            value={password}
                            onChange={this.InputOnchange}
                            error={passwordError}
                            inputStyle={style.login__input}
                            labelText={t('form.password')}
                            labelStyle={style.login__label}
                        />
                        {password.length >= 1 ? (
                            <div
                                onClick={this.showHidePassword}
                                className={style.login__inputWrapper_eye}
                            >
                                <img src={eye} alt="eye" />
                            </div>
                        ) : null}
                    </div>
                    <div className={style.login__buttonWrapper}>
                        <Button
                            id="login-button"
                            type="submit"
                            className={style.login__button}
                            disabled={authLoading}
                        >
                            {t('auth.signInEnter')}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggedIn, loading: authLoading },
    } = state;

    return {
        loggedIn,
        authLoading,
    };
};

Login.defaultProps = {
    t: () => {},
    dispatch: () => {},
    history: {},
    authLoading: false,
};

Login.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    authLoading: PropTypes.bool,
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
)(Login);
