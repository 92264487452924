import React from 'react';
import { DeleteServiceConsumer } from '../../context/delete-service-context';

const withDeleteService = () => Wrapped => props => (
    <DeleteServiceConsumer>
        {
            deleteService => (
                <Wrapped
                    {...props}
                    deleteService={deleteService}
                />
            )
        }
    </DeleteServiceConsumer>
);

export default withDeleteService;
