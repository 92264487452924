import { sentVolumeSell } from '../constants';

const getSentVolumeSell = (state, action) => {
    if (state === undefined) {
        return {
            getSentVolumeSell: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case sentVolumeSell.GET_SENT_VOLUME_SELL_REQUEST:
        return {
            getSentVolumeSell: [],
            loading: true,
            error: false,
        };

    case sentVolumeSell.GET_SENT_VOLUME_SELL_SUCCESS:
        return {
            getSentVolumeSell: action.payload,
            loading: false,
            error: false,
        };

    case sentVolumeSell.GET_SENT_VOLUME_SELL_FAILURE:
        return {
            getSentVolumeSell: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getSentVolumeSell;
