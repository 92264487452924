/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Select, Checkbox } from 'antd';

import Field from '../../../../UI/field';
import DailyVolume from '../../../../UI/daily-volume';
import createProviderBotInputs from '../../../../../helpers/createProviderBotInputs';

import style from './create-provider-bot-view.module.scss';
import './create-provider-bot-view.scss';
import GreenRedButtonBlock from '../../../../UI/green-red-button-block';

const CreateProviderBotView = ({
    createNewBot: {
        source,
        pairName,
        substitutePairName,
        directPairName,
        quantityCuttingEnabled,
        fetchSourceVolume,
        pairSubstituteEnabled,
        quantityLimitForFirstOrder,
        fetchSourceVolumePercents,
        substitutePairPriceCoefficient,
        quantityLimitStepPercents,
        pricePercentsOfQuantityCuttingArea,
        substitutePairAutoPriceEnabled,
        directPairCourse,
        substitutePairAutoPriceCurrencySource,
        substitutePairAutoPriceCurrencyTarget,
        crossRatesEnabled,
        fixedCrossRate,
        fixedCrossRateValue,
        liquidityEnabled,
        liquiditySource
    },
    errors: {
        sourceError,
        pairNameError,
        substitutePairNameError,
        directPairError,
        quantityLimitForFirstOrderError,
        fetchSourceVolumePercentsError,
        substitutePairPriceCoefficientError,
        quantityLimitStepPercentsError,
        pricePercentsOfQuantityCuttingAreaError,
        substitutePairAutoPriceCurrencySourceError,
        substitutePairAutoPriceCurrencyTargetError,
        fixedCrossRateValueError,
        liquiditySourceError
    },
    t,
    times,
    errors,
    allCoins,
    commonPairs,
    targetPairs,
    allSources,
    createNewBot,
    inputOnchange,
    switchCheckBox,
    switchSourceVolume,
    pairSubstitutionToggle,
    timeOnchange,
    selectOnChange,
    refreshDailyPrediction,
    cancelCreateBot,
    submitcreateNewBot,
    createProviderBotLoading,
    totalPercentsOfDailyVolume,
    switchСustomPrice,
    switchDirectBinanceRate,
    currenciesAll,
    switchcrossRatesEnabled,
    fixedCrossRateEnable,
    liquiditySourceEnable
}) => {
    const { Option } = Select;

    const isPairSubstituteEnabled = pairSubstituteEnabled ? targetPairs : commonPairs;

    return (
        <form className={style.createNewBot}>
            <div className={style.createNewBot__titleWrapper}>
                <p className={style.createNewBot__title}>
                    {t('aside.liquidityProvider')} / {t('aside.createNewBot')}
                </p>
                <Checkbox
                    className="pairSubstitution"
                    checked={pairSubstituteEnabled}
                    onChange={pairSubstitutionToggle}
                >
                    {t('createBot.pairSubstitution')}
                </Checkbox>
            </div>
            <div className={style.createNewBot__wrapper}>
                <div className={style.createNewBot__wrapper_leftSide}>
                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('createBot.setSourceLiquidity')}</p>
                        <Select
                            value={source}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {allSources.map(item => (
                                <Option key={item} name="source" value={item}>
                                    {item}
                                </Option>
                            ))}
                        </Select>
                        {sourceError ? (
                            <div className={style.createNewBot__wrapperInput_invalid}>{sourceError}</div>
                        ) : null}
                    </div>
                    <div className={style.createNewBot__wrapperInput}>
                        <p className={style.createNewBot__wrapper_title}>{t('generals.pair')}</p>
                        <Select
                            value={pairName || undefined}
                            className="antDSelect"
                            placeholder={t('generals.choose')}
                            onChange={selectOnChange}
                        >
                            {isPairSubstituteEnabled.map(items => {
                                const { left, right } = items;
                                return (
                                    <Option key={`${left}-${right}`} name="pairName" value={`${left}-${right}`}>
                                        {`${left}-${right}`}
                                    </Option>
                                );
                            })}
                        </Select>
                        {pairNameError ? (
                            <div className={style.createNewBot__wrapperInput_invalid}>{pairNameError}</div>
                        ) : null}
                    </div>

                    {pairSubstituteEnabled ? (
                        <div className={style.createNewBot__wrapperInput}>
                            <p className={style.createNewBot__wrapper_title}>{t('createBot.sourcePair')}</p>
                            <Select
                                value={substitutePairName || undefined}
                                className="antDSelect"
                                placeholder={t('generals.choose')}
                                onChange={selectOnChange}
                            >
                                {allCoins.map(items => {
                                    const { left, right } = items;
                                    return (
                                        <Option
                                            key={`${left}-${right}`}
                                            name="substitutePairName"
                                            value={`${left}-${right}`}
                                        >
                                            {`${left}-${right}`}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {substitutePairNameError ? (
                                <div className={style.createNewBot__wrapperInput_invalid}>
                                    {substitutePairNameError}
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {createProviderBotInputs.map(item => {
                        const {
                            value, label, error, type,
                        } = item;

                        return (
                            <div key={value} className={style.createNewBot__wrapperInput}>
                                <Field
                                    id={value}
                                    type={type}
                                    placeholder={t('generals.typeHere')}
                                    name={value}
                                    value={createNewBot[value]}
                                    error={errors[error]}
                                    onChange={inputOnchange}
                                    inputStyle={style.createNewBot__wrapper_input}
                                    labelText={t(`${label}`)}
                                    labelStyle={style.createNewBot__wrapper_label}
                                />
                            </div>
                        );
                    })}
                    {pairSubstituteEnabled ? (
                        <Fragment>
                            {!substitutePairAutoPriceEnabled ? (
                                <div className={style.createNewBot__wrapperInput}>
                                    <Field
                                        id="substitutePairPriceCoefficient"
                                        type="number"
                                        placeholder={t('generals.typeHere')}
                                        name="substitutePairPriceCoefficient"
                                        value={substitutePairPriceCoefficient}
                                        error={substitutePairPriceCoefficientError}
                                        onChange={inputOnchange}
                                        inputStyle={style.createNewBot__wrapper_input}
                                        labelText={t('createBot.substitutePairPriceCoefficient')}
                                        labelStyle={style.createNewBot__wrapper_label}
                                    />
                                </div>
                            ) : (
                                <Fragment>
                                    {directPairCourse ? (
                                        <div className={style.createNewBot__wrapperInput}>
                                            <p className={style.createNewBot__wrapper_title}>
                                                {t('createBot.directRate')}
                                            </p>
                                            <Select
                                                value={directPairName || undefined}
                                                className="antDSelect"
                                                placeholder={t('generals.choose')}
                                                onChange={selectOnChange}
                                            >
                                                {allCoins.map(items => {
                                                    const { left, right } = items;
                                                    return (
                                                        <Option
                                                            key={`${left}-${right}`}
                                                            name="directPairName"
                                                            value={`${left}-${right}`}
                                                        >
                                                            {`${left}-${right}`}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {directPairError ? (
                                                <div className={style.createNewBot__wrapperInput_invalid}>
                                                    {directPairError}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <Fragment>
                                            <div className={style.createNewBot__wrapperInput}>
                                                <p className={style.createNewBot__wrapper_title}>
                                                    {t('createBot.substitutePairAutoPriceCurrencySource')}
                                                </p>
                                                <Select
                                                    value={substitutePairAutoPriceCurrencySource || undefined}
                                                    className="antDSelect"
                                                    placeholder={t('generals.choose')}
                                                    onChange={selectOnChange}
                                                >
                                                    {currenciesAll.map(items => (
                                                        <Option
                                                            key={items}
                                                            name="substitutePairAutoPriceCurrencySource"
                                                            value={items}
                                                        >
                                                            {items}
                                                        </Option>
                                                    ))}
                                                </Select>
                                                {substitutePairAutoPriceCurrencySourceError ? (
                                                    <div className={style.createNewBot__wrapperInput_invalid}>
                                                        {substitutePairAutoPriceCurrencySourceError}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className={style.createNewBot__wrapperInput}>
                                                <p className={style.createNewBot__wrapper_title}>
                                                    {t('createBot.substitutePairAutoPriceCurrencyTarget')}
                                                </p>
                                                <Select
                                                    value={substitutePairAutoPriceCurrencyTarget || undefined}
                                                    className="antDSelect"
                                                    placeholder={t('generals.choose')}
                                                    onChange={selectOnChange}
                                                >
                                                    {currenciesAll.map(items => (
                                                        <Option
                                                            key={items}
                                                            name="substitutePairAutoPriceCurrencyTarget"
                                                            value={items}
                                                        >
                                                            {items}
                                                        </Option>
                                                    ))}
                                                </Select>
                                                {substitutePairAutoPriceCurrencyTargetError ? (
                                                    <div className={style.createNewBot__wrapperInput_invalid}>
                                                        {substitutePairAutoPriceCurrencyTargetError}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            <div
                                className={classNames(
                                    style.createNewBot__wrapperInput,
                                    style.createNewBot__wrapperInputLabel,
                                )}
                            >
                                <Checkbox checked={substitutePairAutoPriceEnabled} onChange={switchСustomPrice}>
                                    {t('createBot.autoPrice')}
                                </Checkbox>
                            </div>
                            {substitutePairAutoPriceEnabled ? (
                                <div
                                    className={classNames(
                                        style.createNewBot__wrapperInput,
                                        style.createNewBot__wrapperInputLabel,
                                    )}
                                >
                                    <Checkbox checked={directPairCourse} onChange={switchDirectBinanceRate}>
                                        {t('createBot.directBinanceRate')}
                                    </Checkbox>
                                </div>
                            ) : null}
                        </Fragment>
                    ) : null}
                    {quantityCuttingEnabled ? (
                        <Fragment>
                            <div className={style.createNewBot__wrapperInput}>
                                <Field
                                    id="quantityLimitForFirstOrder"
                                    type="number"
                                    placeholder={t('generals.typeHere')}
                                    name="quantityLimitForFirstOrder"
                                    value={quantityLimitForFirstOrder}
                                    error={quantityLimitForFirstOrderError}
                                    onChange={inputOnchange}
                                    inputStyle={style.createNewBot__wrapper_input}
                                    labelText={t('createBot.quantityLimitForFirstOrder')}
                                    labelStyle={style.createNewBot__wrapper_label}
                                />
                            </div>
                            <div className={style.createNewBot__wrapperInput}>
                                <Field
                                    id="quantityLimitStepPercents"
                                    type="number"
                                    placeholder={t('generals.typeHere')}
                                    name="quantityLimitStepPercents"
                                    value={quantityLimitStepPercents}
                                    error={quantityLimitStepPercentsError}
                                    onChange={inputOnchange}
                                    inputStyle={style.createNewBot__wrapper_input}
                                    labelText={t('createBot.quantityLimitStepPercents')}
                                    labelStyle={style.createNewBot__wrapper_label}
                                />
                            </div>
                            <div className={style.createNewBot__wrapperInput}>
                                <Field
                                    id="pricePercentsOfQuantityCuttingArea"
                                    type="number"
                                    placeholder={t('generals.typeHere')}
                                    name="pricePercentsOfQuantityCuttingArea"
                                    value={pricePercentsOfQuantityCuttingArea}
                                    error={pricePercentsOfQuantityCuttingAreaError}
                                    onChange={inputOnchange}
                                    inputStyle={style.createNewBot__wrapper_input}
                                    labelText={t('createBot.pricePercentsOfQuantityCuttingArea')}
                                    labelStyle={style.createNewBot__wrapper_label}
                                />
                            </div>
                        </Fragment>
                    ) : null}
                    <Checkbox
                        className={style.antCheckboxQuantityCutting}
                        checked={quantityCuttingEnabled}
                        onChange={switchCheckBox}
                    >
                        {t('createBot.quantityCutting')}
                    </Checkbox>
                    {fetchSourceVolume ? (
                        <div className={style.createNewBot__wrapperInput}>
                            <Field
                                id="fetchSourceVolumePercents"
                                type="number"
                                placeholder={t('generals.typeHere')}
                                name="fetchSourceVolumePercents"
                                value={fetchSourceVolumePercents}
                                error={fetchSourceVolumePercentsError}
                                onChange={inputOnchange}
                                inputStyle={style.createNewBot__wrapper_input}
                                labelText={t('createBot.sourceVolumePercents')}
                                labelStyle={style.createNewBot__wrapper_label}
                            />
                        </div>
                    ) : null}
                    <Checkbox
                        className={style.antCheckboxSourceVolume}
                        checked={fetchSourceVolume}
                        onChange={switchSourceVolume}
                    >
                        {t('createBot.sourceVolume')}
                    </Checkbox>
                    <Checkbox
                        className={style.antCheckboxCrossRate}
                        checked={crossRatesEnabled}
                        onChange={switchcrossRatesEnabled}
                    >
                        {t('createBot.crossRatesEnabled')}
                    </Checkbox>
                    <Checkbox
                        className={style.antCheckboxFixedCrossRate}
                        checked={fixedCrossRate}
                        onChange={fixedCrossRateEnable}
                    >
                        {t('createBot.fixedCrossRate')}
                    </Checkbox>
                    {fixedCrossRate ? (
                        <div className={style.createNewBot__wrapperInput}>
                            <Field
                                id="fixedCrossRateValue"
                                type="number"
                                placeholder={t('generals.typeHere')}
                                name="fixedCrossRateValue"
                                value={fixedCrossRateValue}
                                error={fixedCrossRateValueError}
                                onChange={inputOnchange}
                                inputStyle={style.createNewBot__wrapper_input}
                                labelText={t('createBot.fixedCrossRateValue')}
                                labelStyle={style.createNewBot__wrapper_label}
                            />
                        </div>
                    ) : null}
                    <Checkbox
                        className={style.antCheckboxFixedCrossRate}
                        checked={liquidityEnabled}
                        onChange={liquiditySourceEnable}
                    >
                        {t('createBot.liquidityEnabled')}
                    </Checkbox>
                    {liquidityEnabled ? (
                        <div className={style.createNewBot__wrapperInput}>
                            <p className={style.createNewBot__wrapper_title}>{t('createBot.setLiquiditySource')}</p>
                            <Select
                                value={liquiditySource}
                                className="antDSelect"
                                placeholder={t('generals.choose')}
                                onChange={selectOnChange}
                            >
                                {allSources.map(item => (
                                    <Option key={item} name="liquiditySource" value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>
                            {liquiditySourceError ? (
                                <div className={style.createNewBot__wrapperInput_invalid}>{liquiditySourceError}</div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
                <div className={style.createNewBot__wrapper_rightSide}>
                    <DailyVolume
                        errors={errors}
                        times={times}
                        onChange={timeOnchange}
                        refreshDailyPrediction={refreshDailyPrediction}
                        totalPercentsOfDailyVolume={totalPercentsOfDailyVolume}
                    />
                </div>
            </div>
            <GreenRedButtonBlock
                greenRedButtonWrapper={style.greenRedButtonWrapper}
                clickGreenBtn={submitcreateNewBot}
                clickRedBtn={cancelCreateBot}
                greenBtnText={t('generals.createNew')}
                redBtnText={t('generals.cancel')}
                disabled={createProviderBotLoading}
            />
        </form>
    );
};

CreateProviderBotView.defaultProps = {
    t: () => { },
    inputOnchange: () => { },
    timeOnchange: () => { },
    selectOnChange: () => { },
    refreshDailyPrediction: () => { },
    cancelCreateBot: () => { },
    submitcreateNewBot: () => { },
    switchCheckBox: () => { },
    switchSourceVolume: () => { },
    pairSubstitutionToggle: () => { },
    switchСustomPrice: () => { },
    switchDirectBinanceRate: () => { },
    errors: {},
    times: {},
    allCoins: [],
    allSources: [],
    commonPairs: [],
    targetPairs: [],
    currenciesAll: [],
    createNewBot: {},
    totalPercentsOfDailyVolume: 0,
    createProviderBotLoading: false,
};

CreateProviderBotView.propTypes = {
    t: PropTypes.func,
    inputOnchange: PropTypes.func,
    timeOnchange: PropTypes.func,
    selectOnChange: PropTypes.func,
    refreshDailyPrediction: PropTypes.func,
    cancelCreateBot: PropTypes.func,
    submitcreateNewBot: PropTypes.func,
    switchCheckBox: PropTypes.func,
    switchSourceVolume: PropTypes.func,
    pairSubstitutionToggle: PropTypes.func,
    switchСustomPrice: PropTypes.func,
    switchDirectBinanceRate: PropTypes.func,
    errors: PropTypes.object,
    times: PropTypes.object,
    allCoins: PropTypes.arrayOf(PropTypes.object),
    allSources: PropTypes.arrayOf(PropTypes.object),
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    currenciesAll: PropTypes.any,
    createNewBot: PropTypes.object,
    totalPercentsOfDailyVolume: PropTypes.number,
    createProviderBotLoading: PropTypes.bool,
};

export default withTranslation()(CreateProviderBotView);
