import { getBotConstants } from '../constants';

const provederBotsRequested = () => ({
    type: getBotConstants.GET_BOT_REQUEST,
});

const provederBotsLoaded = data => ({
    type: getBotConstants.GET_BOT_SUCCESS,
    payload: data,
});

const provederBotsError = error => ({
    type: getBotConstants.GET_BOT_FAILURE,
    payload: error,
});

const fetchProviderBotsAction = getService => () => dispatch => {
    dispatch(provederBotsRequested());
    getService
        .botConfigs()
        .then(data => {
            dispatch(provederBotsLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem('updateTokenTime');
                document.location.reload(true);
            }
            dispatch(provederBotsError(err));
        });
};

export default fetchProviderBotsAction;
