import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import withPostService from '../../../../hoc/with-post-service';
import withGetService from '../../../../hoc/with-get-service';
import { compose } from '../../../../../utils';
import CreateProviderBotView from './create-provider-bot-view';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';
import timeInputs from '../../../../../helpers/daily-volume';
import fetchCreateProviderBotAction from '../../../../../actions/postCreateProviderBot.actions';
import fetchAllCoinsAction from '../../../../../actions/getAllCoins.actions';
import fetchCurrenciesFiatAction from '../../../../../actions/getCurrenciesAll.actions';
import fetchCommonPairsAction from '../../../../../actions/getCommonPairs.actions';
import fetchTargetPairsAction from '../../../../../actions/getTargetPairs.actions';
import fetchAllSourcesAction from '../../../../../actions/getAllSources.actions';
import { personalAreaPath, providerBotsPath } from '../../../../../constants';

let totalPercentsOfDailyVolume = 0;

export class CreateProviderBotContainer extends Component {
    state = {
        createNewBot: {
            status: 'STOPPED',
            source: 'BINANCE',
            pair: {
                left: '',
                right: '',
            },
            pairName: '',
            substitutePair: {
                left: '',
                right: '',
            },
            minOrderAmount: '',
            maxOrderAmount: '',
            minPercentBetweenOrdersPrice: '',
            maxPercentBetweenOrdersPrice: '',
            substitutePairName: '',
            directPair: null,
            directPairName: '',
            frequencySeconds: '',
            pricePercents: '',
            orderBookDepth: '',
            volumePerDay: '',
            quantityPerOrderPercent: '',
            minOrderQuantity: '',
            amountLimitFromSource: '',
            minTimeActivity: '',
            maxTimeActivity: '',
            quantityLimitForFirstOrder: '',
            fetchSourceVolumePercents: '',
            substitutePairPriceCoefficient: '',
            quantityLimitStepPercents: '',
            pricePercentsOfQuantityCuttingArea: '',
            quantityCuttingEnabled: false,
            pairSubstituteEnabled: false,
            fetchSourceVolume: false,
            substitutePairAutoPriceEnabled: false,
            directPairCourse: false,
            substitutePairAutoPriceCurrencySource: '',
            substitutePairAutoPriceCurrencyTarget: '',
            percentsOfDailyVolumeForEachHour: [],
            crossRatesEnabled: false,
            fixedCrossRate: false,
            fixedCrossRateValue: null,
            liquidityEnabled: true,
            liquiditySource: 'BINANCE'
        },
        times: {
            time00_00_01_00: '',
            time01_00_02_00: '',
            time02_00_03_00: '',
            time03_00_04_00: '',
            time04_00_05_00: '',
            time05_00_06_00: '',
            time06_00_07_00: '',
            time07_00_08_00: '',
            time08_00_09_00: '',
            time09_00_10_00: '',
            time10_00_11_00: '',
            time11_00_12_00: '',
            time12_00_13_00: '',
            time13_00_14_00: '',
            time14_00_15_00: '',
            time15_00_16_00: '',
            time16_00_17_00: '',
            time17_00_18_00: '',
            time18_00_19_00: '',
            time19_00_20_00: '',
            time20_00_21_00: '',
            time21_00_22_00: '',
            time22_00_23_00: '',
            time23_00_24_00: '',
        },
        errors: {},
        loading: false,
    };

    componentDidMount() {
        const {
            fetchAllCoins, fetchCommonPairs, fetchTargetPairs, fetchCurrenciesFiat, fetchAllSources,
        } = this.props;
        fetchAllCoins();
        fetchCommonPairs();
        fetchTargetPairs();
        fetchCurrenciesFiat();
        fetchAllSources();
    }

    componentDidUpdate(prevProps, prevState) {
        const { fetchCommonPairs, fetchAllCoins } = this.props;
        const { createNewBot } = this.state;

        if (prevState.createNewBot.source !== createNewBot.source) {
            fetchCommonPairs(createNewBot.source);
            fetchAllCoins(createNewBot.source);
        }
    }

    // save input changes to state
    inputOnchange = event => {
        const { createNewBot } = this.state;
        this.setState({
            createNewBot: {
                ...createNewBot,
                [event.target.name]: event.target.value,
            },
        });
    };

    // save time changes to state
    timeOnchange = event => {
        const { times } = this.state;
        this.setState({
            times: {
                ...times,
                [event.target.name]: event.target.value,
            },
        });
    };

    // save select changes to state
    selectOnChange = (value, name) => {
        const { createNewBot } = this.state;

        if (name.props.name === 'pairName') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    pair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else if (name.props.name === 'substitutePairName') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    substitutePair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else if (name.props.name === 'directPairName') {
            const stringValue = value;
            const splitString = stringValue.split('-');
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                    directPair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    [name.props.name]: value,
                },
            });
        }
    };

    // refreshDailyPrediction
    refreshDailyPrediction = () => {
        message.success('Coming soon!', 2);
    };

    // redirect to bots page
    cancelCreateBot = () => {
        const { history } = this.props;
        history.push(`${personalAreaPath}${providerBotsPath}`);
    };

    switchCheckBox = e => {
        const { createNewBot } = this.state;

        if (e.target.checked === false) {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    quantityLimitForFirstOrder: '',
                    quantityLimitStepPercents: '',
                    pricePercentsOfQuantityCuttingArea: '',
                    quantityCuttingEnabled: false,
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    quantityCuttingEnabled: true,
                },
            });
        }
    };

    switchSourceVolume = e => {
        const { createNewBot } = this.state;

        this.setState({
            createNewBot: {
                ...createNewBot,
                fetchSourceVolume: !!e.target.checked,
                fetchSourceVolumePercents: '',
            },
        });
    };

    switchcrossRatesEnabled = e => {
        const { createNewBot } = this.state;
        
        this.setState({
            createNewBot: {
                ...createNewBot,
                crossRatesEnabled: !!e.target.checked,
            },
        });
    };

    fixedCrossRateEnable = e => {
        const { createNewBot } = this.state;

        this.setState({
            createNewBot: {
                ...createNewBot,
                fixedCrossRate: !!e.target.checked,
                fixedCrossRateValue: '',
            },
        });
    };

    liquiditySourceEnable = e => {
        const { createNewBot } = this.state;

        this.setState({
            createNewBot: {
                ...createNewBot,
                liquidityEnabled: !!e.target.checked,
                liquiditySource: 'BINANCE',
            },
        });
    };

    pairSubstitutionToggle = e => {
        const { createNewBot } = this.state;

        if (e.target.checked === false) {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    pairSubstituteEnabled: false,
                    substitutePairPriceCoefficient: '',
                    substitutePair: null,
                    substitutePairName: '',
                    directPair: null,
                    directPairName: '',
                    pairName: '',
                    substitutePairAutoPriceEnabled: false,
                    directPairCourse: false,
                    substitutePairAutoPriceCurrencySource: '',
                    substitutePairAutoPriceCurrencyTarget: '',
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    pairSubstituteEnabled: true,
                    pairName: '',
                    substitutePairName: '',
                    directPair: null,
                    directPairName: '',
                },
            });
        }
    };

    switchСustomPrice = e => {
        const { createNewBot } = this.state;
        if (e.target.checked === false) {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    substitutePairPriceCoefficient: '',
                    substitutePairAutoPriceCurrencySource: '',
                    substitutePairAutoPriceCurrencyTarget: '',
                    directPair: null,
                    directPairName: '',
                    substitutePairAutoPriceEnabled: false,
                    directPairCourse: false,
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    substitutePairAutoPriceEnabled: true,
                    directPairCourse: false,
                },
            });
        }
    };

    switchDirectBinanceRate = e => {
        const { createNewBot } = this.state;
        if (e.target.checked === false) {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    directPairCourse: false,
                    directPair: null,
                    directPairName: '',
                },
            });
        } else {
            this.setState({
                createNewBot: {
                    ...createNewBot,
                    directPairCourse: true,
                    substitutePairAutoPriceCurrencySource: '',
                    substitutePairAutoPriceCurrencyTarget: '',
                },
            });
        }
    };

    // btn create bot
    submitcreateNewBot = event => {
        event.preventDefault();
        const { t, history, fetchCreateProviderBot } = this.props;
        const { createNewBot, times } = this.state;
        const errors = {};

        const {
            createNewBot: {
                source,
                liquiditySource,
                pairName,
                substitutePairName,
                frequencySeconds,
                pricePercents,
                orderBookDepth,
                volumePerDay,
                percentsOfDailyVolumeForEachHour,
                minOrderQuantity,
                amountLimitFromSource,
                minTimeActivity,
                maxTimeActivity,
                quantityLimitForFirstOrder,
                fetchSourceVolumePercents,
                substitutePairPriceCoefficient,
                quantityLimitStepPercents,
                pricePercentsOfQuantityCuttingArea,
                quantityPerOrderPercent,
                quantityCuttingEnabled,
                fetchSourceVolume,
                pairSubstituteEnabled,
                substitutePairAutoPriceCurrencySource,
                substitutePairAutoPriceCurrencyTarget,
                substitutePairAutoPriceEnabled,
                directPairCourse,
                directPairName,
            },
        } = this.state;

        this.setState({
            createNewBot: {
                ...createNewBot,
                percentsOfDailyVolumeForEachHour: [],
            },
        });

        Object.keys(times).forEach(key => {
            percentsOfDailyVolumeForEachHour.push(times[key]);
        });

        if (!Number.isInteger(+quantityPerOrderPercent)) {
            errors.quantityPerOrderPercentError = `${t('createBot.valueMustBeInteger')}`;
        }

        if (!Number.isInteger(+frequencySeconds)) {
            errors.frequencySecondsError = `${t('createBot.valueMustBeInteger')}`;
        }

        if (!Number.isInteger(+orderBookDepth)) {
            errors.orderBookDepthError = `${t('createBot.valueMustBeInteger')}`;
        }

        if (source.length < 1) {
            errors.sourceError = t('general.selectFromTheList');
        }

        if (liquiditySource.length < 1) {
            errors.iquiditySourceError = t('general.selectFromTheList');
        }

        if (pairSubstituteEnabled && substitutePairName.length < 1) {
            errors.substitutePairNameError = t('general.selectFromTheList');
        }

        if (directPairCourse && directPairName.length < 1) {
            errors.directPairError = t('general.selectFromTheList');
        }

        if (pairSubstituteEnabled && !substitutePairAutoPriceEnabled && substitutePairPriceCoefficient <= 0) {
            errors.substitutePairPriceCoefficientError = t('createBot.valueMustGreaterThanZero');
        }

        if (
            !directPairCourse
            && pairSubstituteEnabled
            && substitutePairAutoPriceEnabled
            && !substitutePairAutoPriceCurrencySource
        ) {
            errors.substitutePairAutoPriceCurrencySourceError = t('general.selectFromTheList');
        }

        if (
            !directPairCourse
            && pairSubstituteEnabled
            && substitutePairAutoPriceEnabled
            && !substitutePairAutoPriceCurrencyTarget
        ) {
            errors.substitutePairAutoPriceCurrencyTargetError = t('general.selectFromTheList');
        }

        if (
            !directPairCourse
            && pairSubstituteEnabled
            && substitutePairAutoPriceEnabled
            && substitutePairAutoPriceCurrencySource === substitutePairAutoPriceCurrencyTarget
        ) {
            errors.substitutePairAutoPriceCurrencySourceError = t('newOrder.differentCurrencies');
            errors.substitutePairAutoPriceCurrencyTargetError = t('newOrder.differentCurrencies');
        }

        if (pairName.length < 1) {
            errors.pairNameError = t('general.selectFromTheList');
        }

        if (frequencySeconds < 1) {
            errors.frequencySecondsError = `${t('generals.minValue')} 1`;
        }

        if (orderBookDepth < 1) {
            errors.orderBookDepthError = `${t('generals.minValue')} 1`;
        }

        if (pricePercents > 100) {
            errors.pricePercentsError = `${t('generals.maxValue')} 100`;
        }

        if (volumePerDay.length > 12) {
            errors.volumePerDayError = t('error.input', { count: 12 });
        }

        if (minOrderQuantity < 0.00000000000001) {
            errors.minOrderQuantityError = `${t('generals.minValue')} 0.00000000000001`;
        }

        if (amountLimitFromSource < 0.1) {
            errors.amountLimitFromSourceError = `${t('generals.minValue')} 0.1`;
        }

        if (volumePerDay.length < 1) {
            errors.volumePerDayError = t('error.input', { count: 1 });
        }

        if (quantityPerOrderPercent.length < 1) {
            errors.quantityPerOrderPercentError = t('error.input', { count: 1 });
        }

        if (pricePercents.length < 1) {
            errors.pricePercentsError = t('error.input', { count: 1 });
        }

        if (+minTimeActivity > +maxTimeActivity || +minTimeActivity === +maxTimeActivity) {
            errors.minTimeActivityError = `${t('generals.minTimeShouldLess')}`;
            errors.maxTimeActivityError = `${t('generals.minTimeShouldLess')}`;
        }

        if (+minTimeActivity < 1 || +minTimeActivity > 58) {
            errors.minTimeActivityError = `${t('generals.mustIntegerNumber')} 1 - 58`;
        }

        if (+maxTimeActivity < 2 || +maxTimeActivity > 59) {
            errors.maxTimeActivityError = `${t('generals.mustIntegerNumber')} 2 - 59`;
        }

        totalPercentsOfDailyVolume = percentsOfDailyVolumeForEachHour.reduce((a, b) => +a + +b, 0);

        if (totalPercentsOfDailyVolume !== 100) {
            timeInputs.map(item => {
                const { error } = item;
                errors[error] = t('createBot.totalSum100');
                return false;
            });
        }

        if (quantityCuttingEnabled) {
            if (+quantityLimitForFirstOrder === 0 || +quantityLimitForFirstOrder < 0) {
                errors.quantityLimitForFirstOrderError = t('createBot.valueMustGreaterThanZero');
            }

            if (+quantityLimitStepPercents === 0 || +quantityLimitStepPercents < 0) {
                errors.quantityLimitStepPercentsError = t('createBot.valueMustGreaterThanZero');
            }

            if (+pricePercentsOfQuantityCuttingArea === 0 || +pricePercentsOfQuantityCuttingArea < 0) {
                errors.pricePercentsOfQuantityCuttingAreaError = t('createBot.valueMustGreaterThanZero');
            }
        }

        if (fetchSourceVolume) {
            if (+fetchSourceVolumePercents < 0 || +fetchSourceVolumePercents > 100) {
                errors.fetchSourceVolumePercentsError = `${t('generals.mustIntegerNumber')} 0 - 100`;
            }
        }

        timeInputs.map(item => {
            const { value, error } = item;

            if (times[value].length < 1) {
                errors[error] = t('error.input', { count: 1 });
            }

            if (times[value] === '0') {
                errors[error] = t('createBot.zeroProhibited');
            }

            return false;
        });

        if (Object.keys(errors).length > 0) {
            message.error(t('general.errorFields'), 2);
            return this.setState({
                errors,
            });
        }

        let request = {};
        Object.keys(createNewBot).forEach(key => {
            request = {
                ...request,
                [key]: createNewBot[key] === '' ? null : createNewBot[key],
            };
        });
        fetchCreateProviderBot(request, t, history);
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const {
            createNewBot, times, loading, error, errors,
        } = this.state;
        const {
            createProviderBotLoading, allCoins, allSources, commonPairs, targetPairs, currenciesAll,
        } = this.props;

        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <CreateProviderBotView
                createNewBot={createNewBot}
                times={times}
                errors={errors}
                allCoins={allCoins}
                commonPairs={commonPairs}
                targetPairs={targetPairs}
                allSources={allSources}
                currenciesAll={currenciesAll}
                createProviderBotLoading={createProviderBotLoading}
                inputOnchange={this.inputOnchange}
                timeOnchange={this.timeOnchange}
                selectOnChange={this.selectOnChange}
                refreshDailyPrediction={this.refreshDailyPrediction}
                cancelCreateBot={this.cancelCreateBot}
                submitcreateNewBot={this.submitcreateNewBot}
                switchCheckBox={this.switchCheckBox}
                switchSourceVolume={this.switchSourceVolume}
                switchcrossRatesEnabled={this.switchcrossRatesEnabled}
                fixedCrossRateEnable={this.fixedCrossRateEnable}
                switchСustomPrice={this.switchСustomPrice}
                switchDirectBinanceRate={this.switchDirectBinanceRate}
                pairSubstitutionToggle={this.pairSubstitutionToggle}
                totalPercentsOfDailyVolume={totalPercentsOfDailyVolume}
                liquiditySourceEnable={this.liquiditySourceEnable}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

CreateProviderBotContainer.defaultProps = {
    t: () => {},
    history: {},
    fetchCreateProviderBot: () => {},
    fetchAllCoins: () => {},
    fetchAllSources: () => {},
    fetchCurrenciesFiat: () => {},
    fetchCommonPairs: () => {},
    fetchTargetPairs: () => {},
    allCoins: [],
    commonPairs: [],
    targetPairs: [],
    allSources: [],
    currenciesAll: [],
    createProviderBotLoading: false,
};

CreateProviderBotContainer.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    fetchCreateProviderBot: PropTypes.func,
    fetchAllCoins: PropTypes.func,
    fetchAllSources: PropTypes.func,
    fetchCurrenciesFiat: PropTypes.func,
    fetchCommonPairs: PropTypes.func,
    fetchTargetPairs: PropTypes.func,
    allCoins: PropTypes.arrayOf(PropTypes.object),
    commonPairs: PropTypes.arrayOf(PropTypes.object),
    targetPairs: PropTypes.arrayOf(PropTypes.object),
    allSources: PropTypes.arrayOf(PropTypes.object),
    currenciesAll: PropTypes.any,
    createProviderBotLoading: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        createProviderBot: { loading: createProviderBotLoading },
        allCoins: { allCoins },
        allSources: { allSources },
        commonPairs: { commonPairs },
        targetPairs: { targetPairs },
        currenciesAll: { data: currenciesAll },
    } = state;

    return {
        createProviderBotLoading,
        allCoins,
        allSources,
        commonPairs,
        targetPairs,
        currenciesAll,
    };
};

const mapDispatchToProps = (dispatch, { getService, postService }) => bindActionCreators(
    {
        fetchCreateProviderBot: fetchCreateProviderBotAction(postService),
        fetchAllCoins: fetchAllCoinsAction(getService),
        fetchCurrenciesFiat: fetchCurrenciesFiatAction(getService),
        fetchCommonPairs: fetchCommonPairsAction(getService),
        fetchTargetPairs: fetchTargetPairsAction(getService),
        fetchAllSources: fetchAllSourcesAction(getService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withPostService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(CreateProviderBotContainer);
