import { accuracySentVolumeBuy } from '../constants';

const getAccuracySentVolumeBuy = (state, action) => {
    if (state === undefined) {
        return {
            accuracySentVolumeBuy: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case accuracySentVolumeBuy.GET_ACCURACY_SENT_VOLUME_BUY_REQUEST:
        return {
            accuracySentVolumeBuy: [],
            loading: true,
            error: false,
        };

    case accuracySentVolumeBuy.GET_ACCURACY_SENT_VOLUME_BUY_SUCCESS:
        return {
            accuracySentVolumeBuy: action.payload,
            loading: false,
            error: false,
        };

    case accuracySentVolumeBuy.GET_ACCURACY_SENT_VOLUME_BUY_FAILURE:
        return {
            accuracySentVolumeBuy: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getAccuracySentVolumeBuy;
