import { getInrBotConstants } from '../constants/getInrBots.constants';

const provederBotsRequested = () => ({
    type: getInrBotConstants.GET_INR_BOT_REQUEST,
});

const provederBotsLoaded = data => ({
    type: getInrBotConstants.GET_INR_BOT_SUCCESS,
    payload: data,
});

const provederBotsError = error => ({
    type: getInrBotConstants.GET_INR_BOT_FAILURE,
    payload: error,
});

const fetchFixedRatesBotsAction = getService => () => dispatch => {
    dispatch(provederBotsRequested());
    getService
        .getfixedrates()
        .then(data => {
            dispatch(provederBotsLoaded(data));
        })
        .catch(err => {
            if (err.response.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem('updateTokenTime');
                document.location.reload(true);
            }
            dispatch(provederBotsError(err));
        });
};

export default fetchFixedRatesBotsAction;
