import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';

import style from './img.module.scss';
import statusOnline from './images/statusOnline.svg';
import statusOffline from './images/statusOffline.svg';
import fastSpeed from './images/fastSpeed.svg';
import slowSpeed from './images/slowSpeed.svg';

const Img = ({
    className,
    userName,
    src,
    alt,
    width,
    height,
    status,
    online,
    speed,
    fast,
    ...args
}) => {
    const showStatus = status ? (
        <img
            className={style.avatar__status}
            src={online ? statusOnline : statusOffline}
            alt="status"
        />
    ) : null;

    const showSpeed = speed ? (
        <img
            className={style.avatar__speed}
            src={fast ? fastSpeed : slowSpeed}
            alt="speed"
        />
    ) : null;

    if (!src) {
        return (
            <div className={style.avatar}>
                <Avatar className="headerUserAvatar" unstyled name={userName} />
                {showStatus}
                {showSpeed}
            </div>
        );
    }
    return (
        <div className={style.avatar}>
            <img {...args} className={className} src={src} alt={alt} />
            {showStatus}
            {showSpeed}
        </div>
    );
};

Img.defaultProps = {
    className: '',
    userName: '',
    src: '',
    alt: '',
    width: '',
    height: '',
    status: false,
    online: true,
    speed: false,
    fast: true,
    onClick: () => {},
};

Img.propTypes = {
    className: PropTypes.string,
    userName: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    status: PropTypes.bool,
    online: PropTypes.bool,
    speed: PropTypes.bool,
    fast: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Img;
