import { accuracySentVolumeSell } from '../constants';

const getAccuracySentVolumeSell = (state, action) => {
    if (state === undefined) {
        return {
            accuracySentVolumeSell: [],
            loading: true,
            error: false,
        };
    }

    switch (action.type) {
    case accuracySentVolumeSell.GET_ACCURACY_SENT_VOLUME_SELL_REQUEST:
        return {
            accuracySentVolumeSell: [],
            loading: true,
            error: false,
        };

    case accuracySentVolumeSell.GET_ACCURACY_SENT_VOLUME_SELL_SUCCESS:
        return {
            accuracySentVolumeSell: action.payload,
            loading: false,
            error: false,
        };

    case accuracySentVolumeSell.GET_ACCURACY_SENT_VOLUME_SELL_FAILURE:
        return {
            accuracySentVolumeSell: [],
            loading: false,
            error: true,
        };

    default:
        return state;
    }
};

export default getAccuracySentVolumeSell;
