/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import withPatchService from '../../../../../hoc/with-patch-service';
import withGetService from '../../../../../hoc/with-get-service';
import withDeleteService from '../../../../../hoc/with-delete-service';
import { compose } from '../../../../../../utils';
import ManualOrdersView from './manual-orders-view';
import ErrorIndicator from '../../../../error-page/error-indicator';
import fetchOneOrderAction from '../../../../../../actions/getOneOrder.actions';
import makeOrderAction from '../../../../../../actions/postMakeOrder.actions';
import cancelOrderAction from '../../../../../../actions/cancelOrder.actions';

const initialState = {
    pairName: '',
    rowCount: [1],
    manualOrdersData: [],
};
export class ManualOrdersContainer extends Component {
    state = { ...initialState };

    componentDidMount() {
        const {
            getOneOrderData: { orders },
        } = this.props;
        this.setState({
            manualOrdersData: orders,
        });
    }

    componentDidUpdate(prevProps) {
        const {
            getOneOrder,
            makeOrderId,
            successMakeOrder,
            makeOrdeData,
            loading,
            getOneOrderData: { orders },
        } = this.props;
        const { rowCount } = this.state;

        if (!loading && loading !== prevProps.loading) {
            this.setState({
                manualOrdersData: orders,
            });
        }

        if (successMakeOrder && successMakeOrder !== prevProps.successMakeOrder) {
            if (makeOrderId) {
                const cleanSuccessRow = rowCount.filter(item => item !== makeOrderId);

                this.setState({
                    [makeOrderId]: null,
                    [`error${makeOrderId}`]: null,
                    rowCount: cleanSuccessRow,
                });
            } else {
                this.resetState();
            }

            makeOrdeData.map(item => {
                const { error } = item;
                if (!error) {
                    const {
                        pair: { left, right },
                    } = item;
                    getOneOrder(`${left}-${right}`);
                }
                return true;
            });
        }
    }

    // save input changes to state
    inputOnchange = (event, id) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            [id]: {
                ...prevState[id],
                [name]: value,
            },
        }));

        if (name === 'amount' && value.length > 0) {
            this.setState(prevState => ({
                [`error${id}`]: {
                    ...prevState[`error${id}`],
                    amountError: '',
                },
            }));
        }

        if (name === 'price' && value.length > 0) {
            this.setState(prevState => ({
                [`error${id}`]: {
                    ...prevState[`error${id}`],
                    priceError: '',
                },
            }));
        }
    };

    validateFields = id => {
        const { t } = this.props;
        const side = this.state[id] && this.state[id].side ? this.state[id].side : '';
        const amount = this.state[id] && this.state[id].amount ? this.state[id].amount : '';
        const price = this.state[id] && this.state[id].price ? this.state[id].price : '';

        if (side.length < 1) {
            this.setState(prevState => ({
                [`error${id}`]: {
                    ...prevState[`error${id}`],
                    sideError: t('general.selectFromTheList'),
                },
            }));
        }

        if (amount.length < 1) {
            this.setState(prevState => ({
                [`error${id}`]: {
                    ...prevState[`error${id}`],
                    amountError: t('error.input', { count: 1 }),
                },
            }));
        }

        if (price.length < 1) {
            this.setState(prevState => ({
                [`error${id}`]: {
                    ...prevState[`error${id}`],
                    priceError: t('error.input', { count: 1 }),
                },
            }));
        }
    };

    // save select changes to state
    selectOnChange = (value, name, id) => {
        if (name.props.name === 'pairName') {
            this.setState({
                pairName: value,
            });
        } else {
            this.setState(prevState => ({
                [id]: {
                    ...prevState[id],
                    [name.props.name]: value,
                },
            }));

            if (name.props.name === 'side' && value.length > 0) {
                this.setState(prevState => ({
                    [`error${id}`]: {
                        ...prevState[`error${id}`],
                        sideError: '',
                    },
                }));
            }
        }
    };

    makePair = id => {
        this.makeRequest(id);
    };

    makeRequest = async id => {
        const {
            t,
            makeOrder,
            match: {
                params: { id: pairName },
            },
        } = this.props;

        await this.validateFields(id);
        const copyErrors = { ...this.state[`error${id}`] };
        const cleanObj = Object.entries(copyErrors).reduce((acc, [key, value]) => {
            if (value) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(cleanObj).length === 0) {
            const stringValue = pairName;
            const splitString = stringValue.split('-');
            const orders = {
                orders: [
                    {
                        ...this.state[id],
                        pair: {
                            left: splitString[0],
                            right: splitString[1],
                        },
                    },
                ],
            };

            makeOrder(pairName, orders, t, id);
        } else {
            message.error(t('general.errorFields'), 2);
        }
    };

    makeAll = pairName => {
        this.makeAllRequest(pairName);
    };

    makeAllRequest = async () => {
        const {
            t,
            makeOrder,
            match: {
                params: { id: pairName },
            },
        } = this.props;

        await this.state.rowCount.map(id => this.validateFields(id));

        const allErrors = [];

        await this.state.rowCount.map(id => {
            const cleanObj = Object.entries(this.state[`error${id}`]).reduce((acc, [key, value]) => {
                if (value) {
                    acc[key] = value;
                }
                return acc;
            }, {});
            return allErrors.push(cleanObj);
        });

        const errorToBoolean = [];

        await allErrors.map(error => {
            if (Object.keys(error).length) {
                errorToBoolean.push(true);
            } else {
                errorToBoolean.push(false);
            }
            return true;
        });

        const isError = errorToBoolean.some(error => error === true);

        if (isError) {
            message.error(t('general.errorFields'), 2);
        } else {
            const orders = [];

            await this.state.rowCount.map(id => {
                const stringValue = pairName;
                const splitString = stringValue.split('-');
                const completedObject = {
                    ...this.state[id],
                    pair: {
                        left: splitString[0],
                        right: splitString[1],
                    },
                };
                return orders.push(completedObject);
            });

            const parameters = {
                orders,
            };

            makeOrder(pairName, parameters, t);
        }
    };

    addRow = () => {
        const { rowCount } = this.state;

        const copyArr = [...rowCount];
        const newRow = +copyArr.slice(-1) + 1;
        copyArr.push(newRow);

        const lastElementOfArray = rowCount.slice(-1)[0];
        const { amount = '', price = '', side = undefined } = this.state[lastElementOfArray] || {};

        this.setState({
            rowCount: copyArr,
            [newRow]: { side, amount, price },
            [`error${newRow}`]: { sideError: '', amountError: '', priceError: '' },
        });
    };

    deleteRow = id => {
        const { rowCount } = this.state;
        const copyArr = [...rowCount];
        const index = copyArr.indexOf(id);

        if (index > -1) {
            copyArr.splice(index, 1);
        }

        this.setState({
            rowCount: copyArr,
            [id]: null,
            [`error${id}`]: null,
        });
    };

    cancel = (pairName, id) => {
        const { t, cancelOrder } = this.props;
        const data = {
            orderIds: [id],
        };
        cancelOrder(pairName, data, t);
    };

    cancelAll = () => {
        const {
            t,
            cancelOrder,
            getOneOrderData: { orders },
            match: {
                params: { id: pairName },
            },
        } = this.props;
        const orderIds = [];
        orders.map(item => orderIds.push(item.id));
        const data = {
            orderIds,
        };
        cancelOrder(pairName, data, t);
    };

    resetState = () => {
        const { pairName } = this.state;
        const keys = Object.keys(this.state);
        const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }), {});
        this.setState({
            ...stateReset,
            ...initialState,
            pairName,
        });
    };

    onError = () => {
        this.setState({
            error: true,
        });
    };

    render() {
        const { error, rowCount, manualOrdersData } = this.state;
        const { getOneOrderData, loadingMakeOrder } = this.props;

        const hasData = !error;
        const errorMessage = error ? <ErrorIndicator /> : null;
        console.log(this.state, 'state');

        const content = hasData ? (
            <ManualOrdersView
                inputOnchange={this.inputOnchange}
                selectOnChange={this.selectOnChange}
                makePair={this.makePair}
                makeAll={this.makeAll}
                deleteRow={this.deleteRow}
                addRow={this.addRow}
                cancel={this.cancel}
                cancelAll={this.cancelAll}
                manualOrdersData={manualOrdersData}
                rowCount={rowCount}
                state={this.state}
                getOneOrderData={getOneOrderData}
                loadingMakeOrder={loadingMakeOrder}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {content}
            </Fragment>
        );
    }
}

ManualOrdersContainer.defaultProps = {
    t: () => {},
    getOneOrder: () => {},
    makeOrder: () => {},
    cancelOrder: () => {},
    makeOrdeData: [],
    getOneOrderData: {},
    match: {},
    loading: true,
    makeOrderId: null,
    successMakeOrder: false,
    loadingMakeOrder: false,
};

ManualOrdersContainer.propTypes = {
    t: PropTypes.func,
    getOneOrder: PropTypes.func,
    makeOrder: PropTypes.func,
    cancelOrder: PropTypes.func,
    makeOrdeData: PropTypes.any,
    getOneOrderData: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.bool,
    makeOrderId: PropTypes.any,
    successMakeOrder: PropTypes.bool,
    loadingMakeOrder: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        savedOrderPair: { success: successSavedOrderPair },
        orders: { data: orders, success: successOrders, loading: ordersLoading },
        makeOrder: {
            data: makeOrdeData, id: makeOrderId, success: successMakeOrder, loading: loadingMakeOrder,
        },
        cancelOrder: { success: successCancelOrder },
        getOneOrder: { success: successgetOneOrder, data: getOneOrderData, loading },
    } = state;

    return {
        successSavedOrderPair,
        successOrders,
        orders,
        ordersLoading,
        makeOrderId,
        successMakeOrder,
        getOneOrderData,
        makeOrdeData,
        successCancelOrder,
        loading,
        successgetOneOrder,
        loadingMakeOrder,
    };
};

const mapDispatchToProps = (dispatch, { getService, deleteService, patchService }) => bindActionCreators(
    {
        getOneOrder: fetchOneOrderAction(getService),
        makeOrder: makeOrderAction(patchService),
        cancelOrder: cancelOrderAction(deleteService),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    withDeleteService(),
    withPatchService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(ManualOrdersContainer);
