import { getOneScheduledOrderConstants } from '../constants';

const getOneScheduledOrders = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: true,
            error: false,
            success: false,
        };
    }

    switch (action.type) {
    case getOneScheduledOrderConstants.GET_ONE_SCHEDULED_ORDER_REQUEST:
        return {
            data: {},
            loading: true,
            error: false,
            success: false,
        };

    case getOneScheduledOrderConstants.GET_ONE_SCHEDULED_ORDER_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: false,
            success: true,
        };

    case getOneScheduledOrderConstants.GET_ONE_SCHEDULED_ORDER_FAILURE:
        return {
            data: {},
            loading: false,
            error: true,
            success: false,
        };

    default:
        return state;
    }
};

export default getOneScheduledOrders;
