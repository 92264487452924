/* eslint-disable */

import { message } from 'antd';
import { userConstants, personalAreaPath, providerBotsPath } from '../constants';
import { userService } from '../services/user.service';
import { alertActions } from '.';

function login(username, password, history) {
    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }

    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password, history).then(
            user => {
                dispatch(success(user));
                dispatch(alertActions.success('Authorization successful'));
                history.push(`${personalAreaPath}${providerBotsPath}`);
                message.success('Authorization successful', 2);
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                message.error('Wrong email or password', 2);
            },
        );
    };
}

function success(user) {
    return {
        type: userConstants.LOGIN_SUCCESS,
        user,
    };
}

function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('updateTokenTime');
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request());

        userService.register(user).then(
            user => {
                dispatch(success(user));
                dispatch(alertActions.success('Registration successful'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                message.error('Username or email already exist', 2);
            },
        );
    };

    function request(user) {
        return {
            type: userConstants.REGISTER_REQUEST,
            user,
        };
    }

    function success(user) {
        return {
            type: userConstants.REGISTER_SUCCESS,
            user,
        };
    }

    function failure(error) {
        return {
            type: userConstants.REGISTER_FAILURE,
            error,
        };
    }
}


export const userActions = {
    login,
    logout,
    register,
    success,
};
