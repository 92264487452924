import { saveOrderPair } from '../constants';

const savedOrderPair = (state, action) => {
    if (state === undefined) {
        return {
            data: {},
            loading: false,
            error: null,
            success: false,
        };
    }

    switch (action.type) {
    case saveOrderPair.SAVE_ORDER_PAIR_REQUEST:
        return {
            data: {},
            loading: true,
            error: null,
            success: false,
        };

    case saveOrderPair.SAVE_ORDER_PAIR_SUCCESS:
        return {
            data: action.payload,
            loading: false,
            error: null,
            success: true,
        };

    case saveOrderPair.SAVE_ORDER_PAIR_FAILURE:
        return {
            data: {},
            loading: false,
            error: action.payload,
            success: false,
        };

    default:
        return state;
    }
};

export default savedOrderPair;
